import {
  CsvSeparator,
  EmailNotificationMap,
  RoleRestrictionProfile,
} from '@deepstream/common/user-utils';
import { ProductTag } from '@deepstream/common/products';
import {
  User as RfqUser,
  ExchangeDefinition,
  Attachment,
  ActionType,
  CompanyMinimized,
  CompanyId,
  Page,
  HirePeriod,
  Sender,
  Company,
  QuestionResponse,
  RfqStatus,
  EvaluationCriterionExchangeDefinition,
  LineItemExchangeDefinition,
  CurrencyExchangeDefinition,
  Live,
  AnyScope,
  GeneralStage,
  AuctionStage,
  AuctionLineItemExchangeDefinition,
  AuctionStatus,
  Address,
  PublishableSettings,
  Language,
  RfxSection,
  AuctionHistoryEvent,
  RfxSummary,
  AuctionRules,
  AuctionBid,
  BidStatus,
  DisabledReason,
  RecipientId,
  SectionId,
  StageId,
  UniversalBidStatus,
  Vessel,
  RfxBidSection,
  Approval,
  StageApprovalExchangeDefinition,
  QuestionExchangeDefinition,
  UserMinimized,
  ResponseTag,
  RfxSpendAndSavings,
  Lot,
  LotId,
  LotIntentionStatus,
  LotsRevisionHistoryEvent,
  PageStatus,
  LiveSettings,
  AwardOrCloseRequestDetails,
} from '@deepstream/common/rfq-utils';
import { ContractExchangeDefinition, ContractExchangeVerifiedData } from '@deepstream/common/contract/contract';
import { BidProgress } from '@deepstream/common/bidProgress';
import { PreQualCategoryType } from '@deepstream/common/preQual';

import { type CompanyFeatureFlags } from './ui/types';

export { CompanyFeatureFlags };
export type Unbox<T> = T extends Promise<infer U> ? U : never;

export interface Currency {
  code: string;
  name: string;
}

export type Currencies = {
  [key: string]: Currency;
};

export type Invite = {
  _id: string;
  email: string;
};

export type InvitedPerson = {
  email: string;
  firstName?: string;
  lastName?: string;
  company?: any;
  companyInput?: string;
};

export type Invitation = {
  message: string;
  locale: string;
};

export interface InviteModalResponse {
  company: CompanyMinimized;
  users: InvitedPerson[];
}

export enum LockCriterion {
  BID_DEADLINE = 'bidDeadline',
  STAGE_DEADLINE = 'stageDeadline',
  TEAM_MEMBER = 'teamMember',
}

export enum LockStatus {
  LOCKED = 'locked',
  UNLOCKED = 'unlocked',
}

export enum LockPermission {
  CAN_UNLOCK = 'canUnlock',
  CANNOT_UNLOCK = 'cannotUnlock',
}

export enum Operator {
  AND = 'and',
  OR = 'or',
}

export enum RequestRole {
  OWNER = 'owner',
  TEAM_MEMBER = 'teamMember',
}

export interface CompanyRoles {
  admin: boolean;
  editor: boolean;
  managePreQual: boolean;
  sendQuestionnaires: boolean;
  receiveQuestionnaires: boolean;
  sendRFQ: boolean;
  receiveRFQ: boolean;
  sendContracts: boolean;
  receiveContracts: boolean;
  accessReportingForRequests: boolean;
  accessReportingForContracts: boolean;
}

export type User = {
  _id: string;
  hashedUserId: string;
  phoneNumber?: string;
  email: string;
  emailPending?: string;
  name: string;
  firstName?: string;
  lastName?: string;
  jobTitles: Record<string, string>;
  roleRestrictionProfiles?: Record<string, RoleRestrictionProfile>;
  roles: Record<string, CompanyRoles>;
  avatarId?: string;
  lastUpdated: string;
  verified?: boolean;
  preferences?: {
    dateFormat?: string;
    locale?: string;
    csvSeparator?: CsvSeparator;
    emailNotifications?: EmailNotificationMap;
  };
  requestedRoles?: {
    [key: string]: {
      status: 'rejected' | 'accepted' | 'pending';
      msg?: string;
      inviteeCompanyName?: string;
      inviterName?: string;
      inviterCompanyName?: string;
      role: string;
      date: Date;
    };
  };
  companyRoles: {
    admin: boolean;
    editor: boolean;
    group: 'buyer' | 'supplier' | 'both';
    name: string;
    receiveContracts: boolean;
    receiveRFQ: boolean;
    sendContracts: boolean;
    sendRFQ: boolean;
    subscription: object; // TODO: check the actual type
    hasSentRequest?: boolean;
    roleRestrictionProfile: RoleRestrictionProfile;
    _id: string;
  }[];
  signedUpAt?: Date;
  pendingCreation?: boolean;
  auth0IsFederated?: boolean;
  hasAcceptedLiveTermsOfService?: boolean;
};

export type CurrentUser = User & { featureFlags: CompanyFeatureFlags };

export type UserPatch = Pick<
  User,
  'firstName' | 'lastName' | 'phoneNumber' | 'jobTitles' | 'preferences'
>;

export interface CompanyAddress {
  lineOne?: string;
  lineTwo?: string;
  city?: string;
  state?: string;
  postcode?: string;
  country?: string;
}

export type SearchState = {
  text: string;
  pageIndex: number;
  pageSize: number;
  supplierListIds: string[];
  deliveryCountries: string[];
  productTag: string;
};

export type ProductSearchResult = {
  results: ProductTag[];
  ancestors: ProductTag[];
};

export type UploadPurpose =
  | 'avatar'
  | 'logo'
  | 'hero'
  | 'rfq'
  | 'bid'
  | 'msg'
  | 'certificate'
  | 'caseStudy'
  | 'supplierlist'
  | 'prequal'
  | 'comment'
  | 'document'
  | 'contract'
  | 'questionnaire'
  | 'bidUpload'
  | 'admin';

export enum TemplateStatus {
  ACTIVE = 'active',
  DELETED = 'deleted',
}

export type DashboardTemplate = {
  _id: string;
  meta: {
    createdAt: Date;
    createdBy: {
      _id: string;
      name: string;
    };
    fromRequest?: string;
    fromTemplate?: string;
    lastEditedAt: Date;
    lastEditedBy: {
      _id: string;
      name: string;
    };
    name: string;
    status: TemplateStatus;
  };
};

export type DashboardRole = 'owner' | 'teamMember' | 'none' | 'requestCreator';

export type SentRequestOverviewDetails = {
  subject: string;
  status: string;
  issueDate: Date;
  issuedBy: string;
  bidDeadline: Date;
  reference: string;
  autoReferenceNumber: number;
  lastEdited: Date;
  suppliersInRequest: number;
  suppliersIntendingToBid: number;
  bidsReceived: number;
  compliantBidsReceived: number;
  isLive: boolean;
};

export type SentRequestOverview = {
  hasAccess: boolean;
  isOwner: boolean;
  role: DashboardRole;
  _id: string;
  meta: {
    createdAt: string;
    lastEdited: {
      date: string;
      user: {
        _id: string;
        name: string;
      };
    };
    status: string;
    extendedStatus: string;
  };
  sentDashboard: SentRequestOverviewDetails;
  auction: {
    status: AuctionStatus;
    endDate: string;
  };
  stageById: Record<
    StructureStage<AnyScope>['_id'],
    Pick<StructureStage<AnyScope>, '_id' | 'name' | 'type' | 'completionDeadline'>
  >;
  universalStatusesByRecipientId: Record<string, UniversalBidStatus | undefined>;
  currentDeadlineByRecipientId: Record<string, Date | null | undefined>;
};

/**
 * A {@link SentRequestOverview} that also includes the names of the
 * request owners, as required on the Requests page.
 */
export type ExtendedSentRequestOverview = SentRequestOverview & {
  sentDashboard: { ownerNamesOrEmails: string[] };
};

export type SentRequestOverviews = {
  totalItems: number;
  companyHasNoLiveRequests?: boolean;
  companyHasNoDraftRequests?: boolean;
  pageIndex: number;
  rfqs: ExtendedSentRequestOverview[];
};

export type ReceivedRequestOverviewDetails = {
  subject: string;
  reference: string;
  autoReferenceNumber: number;
  buyerCompanyId: string;
  buyerCompanyName: string;
  bidStatus: string;
  universalBidStatus: string;
  issueDate: Date;
  issuedBy: string;
  bidDeadline: Date;
  lastEdited: Date;
  isLive: boolean;
  sendersNames: string[];
};

export type ReceivedRequestOverview = {
  hasAccess: boolean;
  role: DashboardRole;
  _id: string;
  receivedDashboard: ReceivedRequestOverviewDetails;
};

/**
 * A {@link ReceivedRequestOverview} that also includes the names of the
 * request owners / team members, as required on the Requests page.
 */
export type ExtendedReceivedRequestOverview = ReceivedRequestOverview & {
  receivedDashboard: {
    ownerNamesOrEmails?: string[];
    teamMemberNamesOrEmails?: string[];
  };
};

export type ReceivedRequestOverviews = {
  totalItems: number;
  pageIndex: number;
  rfqs: ExtendedReceivedRequestOverview[];
};

export type PublicRequestOverview = {
  _id: string;
  subject: string;
  description: string;
  senders: {
    _id: string;
  }[];
  firstStageBidDeadline: Date;
  meta: {
    issueDate: string;
    status: string;
  };
};

export type PublicRequestOverviews = {
  totalItems: number;
  pageIndex: number;
  rfqs: PublicRequestOverview[];
};

export enum PublicBidIntentionStatus {
  SUBMITTED = 'submitted',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export type PublicBidIntention = {
  rfqId: string;
  recipientId: string;
  status?: PublicBidIntentionStatus;
  createdAt: Date;
  decidedAt?: Date;
};

export type OwnerFilterItem = { _id: string; companyIds: string[]; name: string; email?: string };
export type UserFilterItem = { _id: string; name: string; email?: string };
export type BidStatusFilterItem = ([string] | [string, string]);
export type SenderFilterItem = { _id: string; name: string };
export type RequestTagFilterItem = { _id: string; name: string };

export type SystemFeatureFlags = {
  productTagMigrationDone?: boolean;
  requiredTermsAndNameEnabled?: boolean;
  auctionsEnabled?: boolean;
  universalBidStatusEnabled?: boolean;
  managementReportingEnabled?: boolean;
  contractManagementEnabled?: boolean;
  bulkImportEnabled?: boolean;
  lineItemFieldsEnabled?: boolean;
  downloadBidAsXlsxEnabled?: boolean;
  evaluationUpdatesEnabled?: boolean;
  discoveryEnabled?: boolean;
  contractChatbotEnabled?: boolean;
  driveChatbotEnabled?: boolean;
  contractReportingEnabled?: boolean;
  contractAuditEnabled?: boolean;
  newPreQualEnabled?: boolean;
  contractESignatureEnabled?: boolean;
  contractTemplatesEnabled?: boolean;
  questionnaireAuditEnabled?: boolean;
  lineItemsBulkSubmitEnabled?: boolean;
  newRequestFeaturesEnabled?: boolean;
  setupMultiStageLineItemsFlowEnabled?: boolean;
};

export type ExchangeHistoryAction = {
  type: ActionType;
  actionLabelTranslationKey?: string;
  submitLabelTranslationKey?: string;
  attachment?: 'optional' | 'required';
  payload?: any;
  hidden?: boolean;
  disabled?: boolean;
};

// TODO extend server-side ExchangeDefinition and get rid of this
export type PublishedExchangeDefinition = (ExchangeDefinition | ContractExchangeDefinition) & { publisherId: string; creatorId: string };

type ClearAll<T extends Record<any, any>> = {
  [K in keyof T]: T[K] extends Record<any, any> ? ClearAll<T[K]> : undefined | null;
};

export type ExchangeSnapshot = {
  _id: string;
  currentCompanyId: string;
  companies: Record<CompanyId, CompanyMinimized & { group?: string }>;
  hasLock: boolean;
  isLocked: boolean;
  isPending: boolean;
  isResolved: boolean;
  isObsolete: boolean;
  isChat: boolean;
  isClarification: boolean;
  isDocumentRequest: boolean;
  status: string;
  history: any[];
  def: PublishedExchangeDefinition;
  roles: string[];
  actions: Array<ExchangeHistoryAction>;
  turn?: CompanyId[];
  numTurns?: number;
  recipientId?: string;
  latestAttachment?:
  | (Attachment & { isLocked: boolean })
  | (ClearAll<Attachment> & { isLocked: true });
  latestBuyerAttachment?: (Attachment & { isLocked: boolean }) | { isLocked: true };
  latestSupplierAttachment?: (Attachment & { isLocked: boolean }) | { isLocked: true };
  latestResponse?: QuestionResponse;
  latestNonEmptyResponse?: QuestionResponse;
  obsoleteTarget?: { date: Date; type: ActionType };
  disabledReason: null | 'bid-withdrawn' | 'bid-rejected' | 'stage-deadline-expired' | 'bid-deadline-expired';
  extendedDeadline: string | true | null;
  isAwaitingInitialContract?: boolean; // Used for contract exchanges where the document is provided by supplier
  isAwaitingInitiatorApproval?: boolean; // Used for contract exchanges where the document is provided by supplier
  isAwaitingCounterSignature?: boolean; // Used for contract exchanges with manual signature
  isAwaitingEnvelopePublishing?: boolean; // Used for contract exchanges with e-signature
  isAwaitingSubmitterESignature?: boolean; // Used for contract exchanges with e-signature
  isAwaitingCounterESignature?: boolean; // Used for contract exchanges with e-signature
  isAwaitingApproval?: boolean; // Used for contract exchanges with e-signature
  verified?: ContractExchangeVerifiedData; // Used for contract exchanges with e-signature
  isCountersigningDisabled?: boolean; // Used for contract exchanges with e-signature
};

export type ApprovalExchangeSnapshot = ExchangeSnapshot & {
  def: { type: 'approval' };
  requirementsIds: string[];
  resolvedRequirementsIds: string[];
  dirtyExchangeIds: string[];
  acceptance: { userName: string; date: Date } | null;
  // Waiting for the requirements to be completed
  isBlocked: boolean,
  // The approval is accepted
  isApproved: boolean,
  // Requirements are completed but the approval is not accepted
  canApprove: boolean,
  // Requirements have changed since the last acceptance
  needsReview: boolean,
};

export type EvaluationExchangeSnapshot = ExchangeSnapshot & {
  def: EvaluationCriterionExchangeDefinition;
  latestReply: Record<string, unknown>;
};

export type LineItemsExchangeSnapshot = ExchangeSnapshot & {
  def: LineItemExchangeDefinition;
  computedFormulas?: Record<string, number>;
  currency: string;
  latestReply: Record<string, unknown>;
  latestNonEmptyReply: Record<string, unknown>;
  responseTag?: ResponseTag | null;
  supplierReplyByTag?: Record<ResponseTag, Record<string, unknown>> | null;
};

export type CurrencyExchangeSnapshot = ExchangeSnapshot & {
  def: CurrencyExchangeDefinition;
  currencyStack: string[];
  latestCurrency: string;
};

export type AuctionLineItemsExchangeSnapshot = ExchangeSnapshot & {
  def: AuctionLineItemExchangeDefinition;
  latestReply: Record<string, unknown>;
  previousPrice?: number | null;
  currency: string;
};

export type StageApprovalExchangeSnapshot = ExchangeSnapshot & {
  def: StageApprovalExchangeDefinition;
  approvals: Approval[];
};

export type ProgressByPageIdByRequirementGroupIdByStageId = Record<string, Record<string, Record<string, BidProgress>>>;

/**
 * The context of the bid machine extended with optional `progress`
 * and `lineItemsTotal` properties which get added to bids in bid
 * structures (i.e. live structures for a specific recipientId).
 */
export type RfxBid = {
  recipientId: RecipientId;
  status: BidStatus | null;
  prevStatus: BidStatus | null;
  universalStatus: UniversalBidStatus | null;
  prevUniversalStatus: UniversalBidStatus | null;
  stageId: StageId | null;
  visibleStageIds: StageId[];
  enteredStageIds: StageId[];
  activatedStageIds: StageId[];
  /**
   * The dates when the buyer has moved the supplier to a stage, keyed by stageId.
   */
  enterDateByStageId: Record<string, string>;
  disabledReason: DisabledReason | null;
  vessels?: Vessel[];
  sectionById: Record<string, RfxBidSection>;
  vesselPricingSectionId?: SectionId;
  outcome?: {
    date: Date | number;
    stageId: StageId;
    message: string;
  };

  progress?: BidProgress;
  progressByPageId?: Record<string, BidProgress>;
  progressByPageIdByRequirementGroupIdByStageId?: ProgressByPageIdByRequirementGroupIdByStageId;
  lineItemsTotal?: {
    amount: number | null;
    currencyCode: string;
    locked: boolean;
  } | null;
  /**
   * The lot intention statuses by lotId. When an intention for
   * a lot is missing, LotIntentionStatus.NO_RESPONSE is
   * to be assumed.
   */
  intentionStatusByLotId: Record<LotId, LotIntentionStatus>;
};

export type Team = {
  users: Record<RfqUser['_id'], RfqUser>;
  owners: RfqUser['_id'][];
};

export type StructureGeneralStage<Scope extends AnyScope> =
  GeneralStage<Scope> & { liveVersion?: GeneralStage<Live> };

export type StructureAuctionStage<Scope extends AnyScope> =
  AuctionStage<Scope> & { liveVersion?: AuctionStage<Live> };

export type StructureStage<Scope extends AnyScope> =
  StructureGeneralStage<Scope> | StructureAuctionStage<Scope>;

export type AuctionBidFeedback = {
  rank?: number | null;
  isInLead: boolean;
  leadBidPrice?: number;
};

export type AuctionLot = {
  _id: string;
  rules: AuctionRules;
  startDate: string;
  durationSec: number;
  endDate: string,
  extensionSec: number;
  pauseDate: string | null;
  cancellationDate: string | null;
  rankedBids: AuctionBid[];
  bidsByBidderId: Record<string, AuctionBid[]>;
  status: 'pending' | 'open' | 'paused' | 'closed';
  feedback: AuctionBidFeedback | null;
};

export type Auction = {
  _id: string;
  status: AuctionStatus;
  startDate: string;
  endDate: string;
  pauseDate: string | null;
  cancellationDate: string | null;
  history: Array<AuctionHistoryEvent>;
  bidderIds: string[] | null;
  lots: AuctionLot[];
};

export type RfxStructure<Scope extends AnyScope = Live> = {
  meta: {
    hasBeenPubliclyAvailable: boolean;
    awarded?: {
      value: true;
      user: UserMinimized;
      date: Date;
      message?: string;
      attachments?: Attachment[];
    };
    hasMessagesPage?: boolean;
    hasBulletinsSection?: boolean;
    hasVesselPricingSection?: boolean;
    enteredRecipientStageIds?: string[];
    outcomeDetails?: Pick<AwardOrCloseRequestDetails, 'awardScenario' | 'awardDecisionByLotId' | 'awardDecisionByExchangeId' | 'splitDecisionByExchangeId'>;
  };
  autoReferenceNumber: number | null;
  version: number;
  /**
   * The date when the request was last published (either by issuing
   * a revision or by sending the request). `null` when the request has not been sent yet.
   */
  versionTimestamp: Date | number | null;
  /**
   * The date when the request was sent. `null` when the request has not been sent yet.
   */
  firstVersionTimestamp?: Date | number | null;
  /**
   * `status` is `null` unless in a sender structure that got generated without
   * a recipientId.
   */
  status: RfqStatus | null;
  /**
   * `extendedStatus` is `null` unless in a sender structure that got generated without
   * a recipientId. Other than `status`, `extendedStatus` can have the value
   * RfqStatus.DEADLINES_PASSED.
   */
  extendedStatus: RfqStatus | null;
  summary: Partial<RfxSummary> & { isLive?: boolean };
  senders: Sender[];
  recipients: Company[];
  pages: Page[];
  stages: StructureStage<Scope>[];
  stageById: Record<StructureStage<Scope>['_id'], StructureStage<Scope>>;
  lots: Lot<Scope>[];
  lotById: Record<Lot<Scope>['_id'], Lot<Scope>>;
  pageById: Record<Page['_id'], Page>;
  sectionById: Record<RfxSection['_id'], RfxSection>;
  hirePeriodById: Record<HirePeriod['_id'], HirePeriod>;
  bidById: Record<RfxBid['recipientId'], RfxBid>;
  exchangeDefById: Record<ExchangeDefinition['_id'], ExchangeDefinition>;
  teamById: Record<CompanyId, Team>;
  auction: Scope extends Live ? Auction : null;
  settings: PublishableSettings & LiveSettings;
  currencyCode: string;
  spendAndSavings?: RfxSpendAndSavings | null;

  /**
   * Only available in bid structure
   */
  enteredBidPageIds?: string[];
  /**
   * Only available in bid structure
   */
  enteredPageIdsByRequirementGroupIdByStageId?: Record<StageId, Record<Lot['_id'] | 'general', string[]>>;

  // Only available for public requests
  isRecipient: boolean;

  lotsRevisionHistory: LotsRevisionHistoryEvent[];

  /**
   * Indicates whether lots, award scenarios, the new buyer- and
   * supplier-side UX, Excel bid submissions and the new award
   * flow are disabled for this request.
   */
  newFeaturesDisabled: boolean;
};

export interface AdminConfig {
  feedbackEmailFrequency: number;
  requestMaxComplexity: number;
  requestMaxExchangeDefCount: number;
  emailDeliveryAllowList: string[];
  defaultSourceCompanyId?: string;
  featureFlags?: {
    productTagMigrationDone?: boolean;
  };
  languages?: Language[];
}

export type AdminRequestMeta = {
  status: RfqStatus;
  creationTimestamp: Date;
  creatorUsername: string;
  fromTemplateId: string;
  defaultRequestSizeLimits: {
    maxComplexity: number;
    maxExchangeDefCount: number;
  };
  requestSizeLimitOverrides: {
    maxComplexity?: number;
    maxExchangeDefCount?: number;
  };
};

export type ClaimWithCompanyAndUser = {
  company: {
    _id: string;
    name: string;
  };
  user: {
    _id: string;
    name: string;
    email: string;
  };
  claim: {
    date: string;
  };
};

export type LiveStructureAndExchanges = {
  structure: RfxStructure;
  exchanges: ExchangeSnapshot[];
};

export type RequestTeamUser = {
  _id: string;
  companyId: string;
  name?: string;
  email?: string;
  requestRole: RequestRole;
  roleRestrictionProfile?: RoleRestrictionProfile | null;
};

export type ContractTeamUser = {
  _id: string;
  companyId: string;
  name?: string;
  email?: string;
  roleRestrictionProfiles?: {
    [k: string]: RoleRestrictionProfile | undefined
  };
};

export type StatsByRecipientId = Record<string, {
  completionByStageId: Record<string, number | null>;
  currentStageBidCompletion: number | null;
  evaluationCompletion: number | null;
  bidPageStatusById: Record<string, PageStatus>;
  evaluationPageStatusById: Record<string, PageStatus>;
  numLockedItems: number;
  unlockableExchangeIdsBySectionId: Record<string, string[]>;
  numOwnBidActions: number;
  numTeamBidActions: number;
  numOwnEvaluationActions: number;
  numTeamEvaluationActions: number;
}>;

export enum IntegrationDataType {
  INTERNAL_LINE_ITEM_TO_EXTERNAL_LINE_ITEM = 'internalLineItemToExternalLineItem',
  RFX_TO_EXTERNAL_ENTITY = 'rfxToExternalEntity',
  EXTERNAL_COMPANY_TO_INTERNAL_COMPANY = 'externalCompanyToInternalCompany',
}

export enum Resource {
  TEMPLATES = 'templates',
  LISTS = 'lists',
}

export type AuctionOverview = {
  _id: string;
  auctionId: string;
  rfxId: string;
  status: 'pending' | 'active' | 'ended' | 'paused';
  startDate: Date;
  endDates: Date[];
  senderIds: string[];
  bidderIds: string[];
  rfx: {
    subject: string;
    status: RfqStatus;
    senderNames: string[];
  };
};

export type AuctionInitialPricesType = {
  [key: string]: number;
};

export type AwardedRequestOverview = {
  _id: string;
  subject: string;
  awardedRecipients: {
    _id: string;
    name: string;
    address: Address;
  }[];
};

export type IdChanges = {
  add: string[];
  remove: string[];
};

export type MutationArgs = {
  onSuccess?: (...args: any[]) => void;
  onError?: (...args: any[]) => void;
  onSettled?: (...args: any[]) => void;
};

export type RecipientFilterItem = {
  _id: string;
  name: string;
  address: {
    country: string;
  };
};

export enum ContractType {
  PRIMARY_CONTRACT = 'primaryContract',
  ADDENDUM = 'addendum',
  LEGACY_CONTRACT = 'legacyContract',
}

export type PreQualCategory = {
  companyId: string;
  createdAt: Date;
  name: string;
  questionIds: string[];
  type: PreQualCategoryType;
  _id: string;
  __v: number;
};

export type PreQualQuestion = {
  isDeleted: boolean;
  companyId: string;
  currentVersion: {
    createdAt: Date;
    exchangeDef: QuestionExchangeDefinition;
  };
  versions: {
    createdAt: Date;
    exchangeDef: QuestionExchangeDefinition;
  }[];
  _id: string;
  __v: number;
};

export type ModelType =
  | 'request'
  | 'template'
  | 'contract'
  | 'contractTemplate'
  | 'questionnaireTemplate'
  | 'questionnaire'
  | 'supplierList';
