import type { ContractDocumentExchangeDefinition, ApprovalExchangeDefinition, LegacyContractExchangeDefinition } from '../contract';
import { FieldType } from '../exchangesConfig';
import { ProductTag } from '../products';
import type { DetailsChange, SenderChange, TeamMembersChange, ExchangeChange, AssetChange } from './changeTypes';
import type { SerializedDate } from './extended-datetime';

export type Compact<T> = { [K in keyof T]: T[K] };
export type Modify<T, R> = Omit<T, keyof R> & R;

export type Draft = 'draft';
export type Live = 'live';
export type AnyScope = Draft | Live;

// this is a copy of the moment.unitOfTime.DurationConstructor type
export type HirePeriodUnit =
  'year' | 'years' | 'y' |
  'month' | 'months' | 'M' |
  'week' | 'weeks' | 'w' |
  'day' | 'days' | 'd' |
  'hour' | 'hours' | 'h' |
  'minute' | 'minutes' | 'm' |
  'second' | 'seconds' | 's' |
  'millisecond' | 'milliseconds' | 'ms' |
  'quarter' | 'quarters' | 'Q';

export enum TimeUnit {
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days',
  MONTHS = 'months',
  YEARS = 'years',
}

export interface TimeFrameDeadline {
  number: number;
  timeUnit: TimeUnit;
  utcOffset: number;
}

export type CompanyId = string;
export type RecipientId = CompanyId;
export type RfqId = string;
export type ExchangeId = string;
export type StageId = string;
export type SectionId = string;
export type PageId = string;
export type UserId = string;
export type LotId = string;

export enum StageType {
  GENERAL = 'general',
  AUCTION = 'auction',
}

export interface GeneralStage<Scope extends AnyScope = Live> {
  _id: StageId;
  // TODO make required once always included in machine and reducer
  // based projections
  type?: StageType.GENERAL;
  name: string;
  intentionDeadline?: TimeFrameDeadline | null;
  completionDeadline: Scope extends Draft ? Date | null : Date;
  isPrivate?: boolean;
  isLive?: boolean;
}

export interface AuctionStage<Scope extends AnyScope = Live> {
  _id: StageId;
  type: StageType.AUCTION;
  name: string;
  startDate: Scope extends Draft ? Date | null : Date;
  completionDeadline: Scope extends Draft ? Date | null : Date;
  isPrivate?: boolean;
  isLive?: boolean;
}

export type Stage<Scope extends AnyScope = Live> = GeneralStage<Scope> | AuctionStage<Scope>;

export type Lot<Scope extends AnyScope = Live> = {
  _id: string;
  name: Scope extends Draft ? string : string | undefined;
  description: Scope extends Draft ? string : string | undefined;
  isObsolete?: boolean;
  isLive?: boolean;
  liveVersion?: Lot<Scope>;
};

export enum ScoringType {
  /**
   * Each evaluator submits a score per criterion. These individual scores are
   * combined to an average score. Evaluators can only see their own score.
   */
  INDIVIDUAL_SCORES = 'individualScores',
  /**
   * Evaluators agree on a single score per criterion. This score can be
   * submitted and seen by any evaluator.
   */
  SINGLE_SCORE = 'singleScore',
}

export type PublishableSettings = {
  isEvaluationEnabled: boolean;
  scoringType: ScoringType;
  isPubliclyAvailable: boolean;
  areLotsEnabled: boolean;
  awardScenarios: {
    requestLevelAward: boolean;
    lineLevelAward: boolean;
    lotLevelAward: boolean;
    noAward: boolean;
  };
  canSplitAwards: boolean;
};

export type LiveSettings = {
  allowClarificationAttachments: boolean;
};

export enum DocumentExchangeSupertype {
  SUBMIT = 'submit', // @deprecated
  REQUEST = 'request', // @deprecated
  DOCUMENT = 'document',
}

export enum ExchangeType {
  HIRE_PERIOD = 'hirePeriod',
  FEES = 'fees',
  TERMS = 'additionalTerms',
  INCLUSIONS = 'inclusionsAndExclusions',
  CURRENCY = 'currency',
  LINE_ITEM = 'lineItem',
  PAYMENT_STAGE = 'paymentStage',
  INCOTERMS = 'incoterms',
  DELIVERY_DEADLINE = 'deliveryBy',
  DELIVERY_ADDRESS = 'deliveryTo',
  COMPLETE_OR_SIGN = 'completeAndSign',
  COMPLETE_OR_SIGN_CLOSED = 'completeAndSignClosed',
  COMPLETE_OR_SIGN_LOCKED = 'completeAndSignLocked',
  ACCEPT_CLOSED = 'acceptClosed',
  ACCEPT = 'agreeDeviation',
  DOCUMENT_REQUEST = 'requestDoc',
  DOCUMENT_REQUEST_CLOSED = 'requestDocClosed', // @deprecated
  DOCUMENT_REQUEST_LOCKED = 'requestDocLocked',
  INFORMATION = 'information',
  CHAT = 'chat',
  CLARIFICATION = 'clarification',
  CHAT_NO_RECEIVER_UPLOAD = 'chatNoSupplierUpload',
  CHAT_ONLY_RECEIVER_UPLOAD = 'chatOnlyReceiverUpload',
  INTERNAL_DOCUMENT = 'internalDocument',
  EVALUATION_CRITERION = 'evaluationCriterion',
  BULLETIN = 'bulletin',
  QUESTION = 'question',
  AUCTION_TERMS = 'auctionTerms',
  AUCTION_INFORMATION = 'auctionInformation',
  AUCTION_LINE_ITEM = 'auctionLineItem',
  CONTRACT = 'contract',
  LEGACY_CONTRACT = 'legacyContract',
  APPROVAL = 'approval',
  STAGE_APPROVAL = 'stageApproval',
}

export type DocumentExchangeType =
  | ExchangeType.COMPLETE_OR_SIGN
  | ExchangeType.COMPLETE_OR_SIGN_CLOSED
  | ExchangeType.COMPLETE_OR_SIGN_LOCKED
  | ExchangeType.ACCEPT_CLOSED
  | ExchangeType.ACCEPT
  | ExchangeType.DOCUMENT_REQUEST
  | ExchangeType.DOCUMENT_REQUEST_CLOSED
  | ExchangeType.DOCUMENT_REQUEST_LOCKED
  | ExchangeType.INFORMATION;

export enum SectionType {
  DELIVERY = 'delivery',
  PAYMENT = 'payment-milestones',
  DOCUMENT = 'document',
  LINE_ITEMS = 'line-items',
  VESSEL_PRICING = 'vessel-pricing',
  CHAT = 'chat',
  INTERNAL_DOCUMENT = 'internal-document',
  CLARIFICATIONS = 'clarifications',
  EVALUATION = 'evaluation',
  BULLETINS = 'bulletins',
  QUESTION = 'question',
  AUCTION_TERMS = 'auction-terms',
  AUCTION_LINE_ITEMS = 'auction-line-items',
  CONTRACT = 'contract',
  STAGE_APPROVAL = 'stage-approval',
}

export type MessagesSectionType =
  | SectionType.CHAT
  | SectionType.CLARIFICATIONS
  | SectionType.BULLETINS;

export type OutsideBidSectionType =
 | SectionType.INTERNAL_DOCUMENT
 | SectionType.STAGE_APPROVAL;

export enum ExchangeProvider {
  NONE = 'none',
  BUYER = 'buyer',
  SUPPLIER = 'supplier',
  BOTH = 'both',
}

export enum ActionType {
  NONE = 'none',
  UNLOCK = 'unlock',
  INITIATE = 'initiate',
  ACCEPT = 'accept',
  REJECT = 'reject',
  DEVIATE = 'deviate',
  SUBMIT = 'submit',
  COUNTERSIGN = 'countersign',
  OBSOLETE_ACTION = 'obsolete-action',
  OBSOLETE_EXCHANGE = 'obsolete-exchange',
  UNOBSOLETE_EXCHANGE = 'unobsolete-exchange',
  REVISE = 'revise',
  CLOSE = 'close',
  RESOLVE = 'resolve',
  REFER_TO_BULLETIN = 'refer-to-bulletin',
  MARK_AS_AGREED = 'mark-as-agreed',
  BID_DEADLINE_REACHED = 'bid-deadline-reached',
  REENABLE_RESPONSES = 'reenable-responses',
  DISABLE_RESPONSES = 'disable-responses',
  REPLACE = 'replace',
  AGREE = 'agree', // Specific to the contract exchange
  APPROVE = 'approve', // Specific to the contract exchange
  UPLOAD_DOCUMENT = 'upload-document', // Specific to the contract exchange
  APPROVE_DOCUMENT = 'approve-document', // Specific to the contract exchange
  REQUIRE_MORE_INFO = 'require-more-info', // Specific to the question exchange in the context of a questionnaire

  // Verified action types
  VERIFIED_ENVELOPE_PUBLISHED = 'verified-envelope-published',
  VERIFIED_RECIPIENT_SIGNED = 'verified-recipient-signed',
  VERIFIED_RECIPIENT_REJECTED = 'verified-recipient-rejected',
  VERIFIED_ENVELOPE_COMPLETE = 'verified-envelope-complete',
  VERIFIED_ENVELOPE_SETUP_FAILED = 'verified-envelope-setup-failed',
}

export enum ActionSubtype {
  UPDATE = 'update',
  SUBMIT = 'submit',
  CLEAR = 'clear',
  ADD_DOCUMENT = 'add-document',
  REQUEST_CURRENCY = 'request-currency',
  SPECIFY_DOCUMENT_EXCHANGE = 'specify-document-exchange',
  REVISE_QUESTION = 'revise-question',
  REVISE_QUESTION_CONFIGURATION = 'revise-question-configuration',
  CLEAR_CURRENCY = 'clear-currency',
  SET_CURRENCY = 'set-currency',
  UPDATE_CURRENCY = 'update-currency',
  RESOLVE_EXCHANGE = 'resolve-exchange',
  PREVIOUS_ACTION = 'previous-action',
  REFER_TO_BULLETIN = 'refer-to-bulletin',
}

export enum ExchangeRole {
  INITIATOR = 'initiator',
  RECEIVER = 'receiver',
}

// order is significant
export enum PageRole {
  /** Cannot read, respond or edit */
  NONE = 'hidden',
  /** Can read but cannot respond or edit */
  READER = 'read',
  /** Can read and respond with comments but cannot edit */
  COMMENTER = 'comment',
  /** Can read and respond with comments or any other action but cannot edit */
  RESPONDER = 'respond',
  /** Can read, respond, edit */
  EDITOR = 'write',
}

export enum AuditRole {
  TEAM_MEMBER = 'teamMember',
  OWNER = 'owner',
  SUPER_USER = 'superUser',
}

export enum LockType {
  STAGE_DEADLINE = 'stage-deadline',
  TEAM_MEMBER = 'team-member',
  TEAM_MEMBER_AFTER_STAGE_DEADLINE = 'team-member-after-stage-deadline',

  /** @deprecated */
  BID_DEADLINE = 'bid-deadline',
  /** @deprecated */
  TEAM_MEMBER_AFTER_BID_DEADLINE = 'team-member-after-bid-deadline',
  /** @deprecated */
  BUYER = 'buyer',
  /** @deprecated */
  CLIENT = 'client',
}

/** @deprecated */
export enum LockingType {
  BID_DEADLINE = 'bid-deadline',
  BUYER = 'buyer',
  CLIENT = 'client',
}

export enum InclusionType {
  FIXED = 'fixed',
  FLEXIBLE = 'flexible',
}

export enum InclusionOption {
  INCLUDED = 'included',
  EXCLUDED = 'not-included',
  FLEXIBLE = 'supplier-can-specify',
}

export enum RfqStatus {
  DRAFT = 'draft',
  LIVE = 'live',
  AWARDED = 'awarded',
  DELETED = 'deleted',
  CLOSED = 'closed',
  /**
   * This status gets shown (only) to buyers
   * when the last deadline of a live request
   * has passed.
   */
  DEADLINES_PASSED = 'deadlinesPassed',
}

export enum BidStatus {
  DELIVERED = 'delivered',
  OPENED = 'opened',
  WITHDRAWN = 'withdrawn',
  BOUNCED = 'bounced',
  BLOCKED = 'blocked',
  NO_RESPONSE = 'yetToRespond',
  NO_INTEND_TO_BID = 'noIntendToBid',
  INTERESTED = 'interested',
  INTEND_TO_BID = 'intendToBid',
  IN_PROGRESS = 'inProgress',

  // The key/value not matching is on purpose. 'bidCompliant' is an
  // outdated value but is now used to indicated completion
  COMPLETE = 'bidCompliant',

  BID_SUBMITTED = 'bidSubmitted',
  BID_DEADLINE_MISSED = 'bidDeadlineMissed',
  AWARDED = 'awarded',
  UNSUCCESSFUL = 'unsuccessful',
  WAITING_FOR_BUYER = 'waitingForBuyer',
}

export enum UniversalBidStatus {
  NO_RESPONSE = 'yetToRespond',
  NO_INTEND_TO_BID = 'noIntendToBid',
  INTERESTED = 'interested',
  WITHDRAWN = 'withdrawn',
  BID_DEADLINE_MISSED = 'bidDeadlineMissed',
  AWARDED = 'awarded',
  UNSUCCESSFUL = 'unsuccessful',
  /**
   * The following BidStatus values map to UniversalBidStatus.ACTIVE:
   *
   * - BidStatus.INTEND_TO_BID = 'intendToBid' ('Incomplete')
   * - BidStatus.IN_PROGRESS = 'inProgress' ('Incomplete')
   * - BidStatus.WAITING_FOR_BUYER = 'waitingForBuyer' ('Waiting for buyer')
   * - BidStatus.COMPLETE = 'bidCompliant' ('Complete')
   */
  ACTIVE = 'active',
  /**
   * Only set when processing the `PendingRecipientEmailStatusUpdatedEvent`.
   */
  DELIVERED = 'delivered',
  /**
   * Only set when processing the `PendingRecipientEmailStatusUpdatedEvent`.
   */
  OPENED = 'opened',
  /**
   * Only set when processing the `PendingRecipientEmailStatusUpdatedEvent`.
   */
  BOUNCED = 'bounced',
  /**
   * Only set when processing the `PendingRecipientEmailStatusUpdatedEvent`.
   */
  BLOCKED = 'blocked',
  /**
   * Not part of any existing RfqEnvelope; only set in `bidReducer.ts`,
   * in a block marked as deprecated.
   */
  BID_SUBMITTED = 'bidSubmitted',
}

/**
 * The status of a request with regards to whether
 * a supplier can interact with the request.
 *
 * Shown on the new recipient pages as "Request status".
 */
export enum RequestInteractivityStatus {
  /**
   * The request has been awarded and thus bidding is no
   * longer possible.
   */
  ENDED = 'ended',
  /**
   * The request has not been awarded and at least one of the
   * request's stages is active, allowing suppliers to bid.
   */
  LIVE = 'live',
  /**
   * The request has not been awarded but none of the
   * request's stages is active.
   */
  PENDING = 'pending',
}

/**
 * The status of a supplier's intention to bid.
 *
 * Shown on the new recipient pages as "Bid status".
 */
export enum BidIntentionStatus {
  /**
   * The supplier has not send an intention to bid.
   */
  NO_RESPONSE = 'noResponse',
  /**
   * The supplier has declined to bid.
   */
  DECLINED_TO_BID = 'declinedToBid',
  /**
   * The supplier has withdrawn the bid.
   */
  BID_WITHDRAWN = 'bidWithdrawn',
  /**
   * The supplier has accepted to bid.
   */
  BIDDING = 'bidding',
}

/**
 * The status of the buyer-provided outcome that a supplier's bid has reached.
 *
 * Shown on the new recipient pages as "Bid outcome".
 */
export enum BidOutcomeStatus {
  /**
   * The buyer has awarded the request to the supplier.
   */
  AWARDED = 'awarded',
  /**
   * The buyer has awarded the request, but not to this supplier.
   */
  UNSUCCESSFUL = 'unsuccessful',
  /**
   * The buyer has not yet awarded the request.
   */
  PENDING = 'pending',
}

export enum StageStatus {
  /**
   * The stage is live, allowing suppliers to bid.
   *
   * Applies to exchange and auction stages.
   */
  LIVE = 'live',
  /**
   * The stage deadline has passed.
   *
   * Applies only to exchange stages.
   */
  DEADLINE_PASSED = 'deadlinePassed',
  /**
   * The buyer has not yet moved the supplier to the stage.
   *
   * Applies to exchange and auction stages.
   */
  PENDING = 'pending',
  /**
   * The has not started yet.
   * Applies only to auction stages.
   */
  SCHEDULED = 'scheduled',
  /**
   * The auction has been paused.
   *
   * Applies only to auction stages.
   */
  PAUSED = 'paused',
  /**
   * The auction has ended.
   *
   * Applies only to auction stages.
   */
  ENDED = 'ended',
  /**
   * The auction stage has been cancelled.
   *
   * Applies only to auction stages.
   */
  CANCELLED = 'cancelled',
}

/**
 * The status of a supplier's intention to bid with regards
 * to a specific lot.
 */
export enum LotIntentionStatus {
  /**
   * The supplier has not send an intention to bid.
   */
  NO_RESPONSE = 'noResponse',
  /**
   * The supplier has declined to bid.
   */
  DECLINED_TO_BID = 'declinedToBid',
  /**
   * The supplier has withdrawn the bid.
   */
  BID_WITHDRAWN = 'bidWithdrawn',
  /**
   * The supplier has accepted to bid.
   */
  BIDDING = 'bidding',
}

export enum PageStatus {
  COMPLETE = 'complete',
  EMPTY = 'empty',
  /**
   * Waiting for supplier.
   */
  WAITING = 'waiting',
  /**
   * Waiting for buyer. Not user-specific. Used in reporting.
  */
  REVIEW = 'review',
  /**
   * Waiting for buyer. User-specific: current user cannot respond; used in app UI.
   */
  WAITING_FOR_TEAM = 'waitingForTeam',
  /**
   * Waiting for buyer. User-specific: current user can respond; used in app UI.
   */
  ACTION_REQUIRED = 'actionRequired',
}

export enum StageApprovalStatus {
  COMPLETE = 'complete',
  CANCELLED = 'cancelled',
  PENDING = 'pending',
}

export enum ExchangeSource {
  BID_AWARDED = 'bidAwarded',
  BID_DECLINED = 'bidDeclined',
  BID_WITHDRAWN = 'bidWithdraw',
  BID_REJECTED = 'bidRejected',
  BID_REINSTATED = 'bidReinstated',
}

export enum ExchangeStatus {
  INFORMATION_ONLY = 'informationOnly',
  ACTION_REQUIRED = 'actionRequired',
  WAITING_FOR_BUYER = 'waitingForBuyer', // Used in the UI for turn-based INCOMPLETE exchanges
  WAITING_FOR_SUPPLIER = 'waitingForSupplier', // Used in the UI for turn-based INCOMPLETE exchanges
  WAITING_FOR_COUNTER_PARTY = 'waitingForCounterParty', // Used in the UI for turn-based INCOMPLETE exchanges
  WAITING_FOR_TEAM = 'waitingForTeam', // Used in the UI for when current user cannot respond to request due to permissions
  COMPLETE = 'complete',
  INCOMPLETE = 'incomplete',
  OBSOLETE = 'obsolete',
  OPEN = 'open',
  CLOSED = 'closed',
  RESOLVED = 'resolved',
  SIGNED = 'signed', // For Contract exchange
  ACTIVE = 'active', // For legacy contract exchange
  SETTING_UP_E_SIGNATURE = 'settingUpESignature', // For contract exchange
  LOCKED = 'locked', // For contract exchange
  BLOCKED = 'blocked', // The exchange cannot be completed because of some dependencies (eg contract approvals)

  PREVIOUS_STAGE_INCOMPLETE = 'previousStageIncomplete',
}

export type History = Action[];

export interface User {
  _id: string;
  name: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  isOwner?: boolean;
  approvingPages?: string[];
  rfqRoles?: {
    [key: string]: PageRole;
  };
  contractRoles?: {
    [key: string]: PageRole;
  };
  jobTitles?: {
    [key: string]: string;
  };
  requestedRoles?: {
    [key: string]: Record<string, any>;
  };
  preferences?: {
    locale: string;
    dateFormat: string;
    multiline: boolean;
    hideTour: {
      rfqDashboard: boolean;
    };
    tableSize: number;
    csvSeparator: string;
  };
  avatarUrl?: string;
  verified?: boolean;
  phoneNumber?: string;
  hashedUserId?: string;
  /** @deprecated */
  rfqRole?: string;
}

/**
 * @deprecated
 * Use `RequestUser` instead
 */
export interface SenderUser extends User {
  company: CompanyMinimized;
}

export interface RequestUser extends User {
  company: CompanyMinimized;
}

export interface UserMinimized {
  _id: string;
  name: string;
}

export enum AttachmentPurpose {
  AVATAR = 'avatar',
  LOGO = 'logo',
  HERO = 'hero',
  RFQ = 'rfq',
  CONTRACT = 'contract',
  BID = 'bid',
  MESSAGE = 'message',
  CERTIFICATE = 'certificate',
  CASES_STUDY = 'caseStudy',
  SUPPLIER_LIST = 'supplierlist',
  PREQUAL = 'prequal',
  COMMENT = 'comment',
  DOCUMENT = 'document',
  ADMIN = 'admin',
  QUESTIONNAIRE = 'questionnaire',
  INTEGRATION_REPORT = 'integrationReport',
  BID_UPLOAD = 'bidUpload',
  /**
   * @deprecated
   */
  USER_REPORT = 'userReport',
  USER_REPORT_TMP = 'userReportTmp',
}

export type Attachment = {
  _id: string;
  name: string;
  companyId: string;
  mimetype: string;
  notSaved?: boolean;
  title?: string;
  userId?: string;
  purpose?: AttachmentPurpose;
};

export enum FeeType {
  DAY_RATE = 'Day rate',
  LUMP_SUM = 'Lump sum fee',
}

export interface HirePeriod {
  _id: string;
  name: string;
  fromDate: string;
  toDate: string;
  isObsolete?: boolean;
  isLive?: boolean;
  liveVersion?: HirePeriod;
}

export interface ExpandedHirePeriodInterval {
  hirePeriodId?: string;
  intervalType: 'Firm' | 'Option';
  amount: number;
  unit: HirePeriodUnit;
  index: number;
  quantity: number;
}

export interface Lock {
  type: LockType;
  keys?: string[];
  bypass?: string[];
}

export enum QuestionType {
  SHORT_TEXT = 'shortText',
  LONG_TEXT = 'longText',
  MULTIPLE_CHOICE = 'multipleChoice',
  CHECKBOXES = 'checkboxes',
  PRICE = 'price',
  ADDRESS = 'address',
  DATE_TIME = 'datetime',
  DOCUMENT = 'document',
  GRID = 'grid',
  YES_NO = 'yesNo',
}

export enum ShortTextSchemaType {
  INTEGER = 'integer',
  DECIMAL = 'decimal',
}

export type ShortTextSchema<Scope extends AnyScope = Live> =
  Scope extends Draft
    ? {
      type: ShortTextSchemaType;
      min?: number;
      max?: number;
    }
    : {
      type: ShortTextSchemaType;
      min: number;
      max: number;
    };

export enum QuestionFormat {
  DATE = 'date',
  TIME = 'time',
  DATETIME = 'datetime',
  INVALID = 'invalid',
}

export type DraftQuestionFormat = QuestionFormat;
export type LiveQuestionFormat = Exclude<QuestionFormat, QuestionFormat.INVALID>;

export enum AuctionBidFeedbackType {
  RANK_AND_LEAD = 'rankAndLead',
  RANK = 'rank',
  LEADING = 'leading',
}

export enum MinimumReductionType {
  AMOUNT = 'amount',
  PERCENT = 'percent',
}

export enum AutoExtensionTrigger {
  NEW_LEAD_BID = 'newLeadBid',
  ANY_BID = 'anyBid',
}

export type AuctionBid = {
  date: string;
  bidderId: string;
  price: number;
  breakdown: Record<string, { price: number; quantity: number }>;
};

export enum AuctionBidRejectedReason {
  INVALID_BID = 'invalid-bid',
  PRE_BIDS_DISALLOWED = 'pre-bids-disallowed',
  TIE_BIDS_DISALLOWED = 'tie-bids-disallowed',
  LATE_BID = 'late-bid',
  EXCEEDS_CEILING_PRICE = 'exceeds-ceiling-price',
  INSUFFICIENT_REDUCTION = 'insufficient-reduction',
}

export type AuctionHistoryEvent =
  | { type: 'auction-started'; date: string }
  | { type: 'auction-ended'; date: string }
  | { type: 'auction-cancelled'; date: string }
  | { type: 'auction-bidding-paused'; date: string }
  | { type: 'auction-bidding-resumed'; date: string }
  | { type: 'auction-lot-bidding-opened'; date: string }
  | { type: 'auction-lot-bidding-closed'; date: string }
  | { type: 'auction-lot-bidding-extended'; date: string; lotId: string; remainingTimeSec: number; trigger?: ExtensionTrigger }
  | { type: 'auction-bidder-agreement-accepted'; date: string; bidderId: string }
  | { type: 'auction-bidder-agreement-revised'; date: string } // TODO remove?
  | { type: 'auction-bidder-agreement-initial'; }
  | { type: 'auction-lot-bid-accepted'; date: string; lotId: string; bidderId: string; bid: AuctionBid; }
  | { type: 'auction-lot-bid-rejected'; date: string; lotId: string; bidderId: string; bid: AuctionBid, reason: AuctionBidRejectedReason }
  | { type: 'auction-lot-bid-retracted'; date: string; lotId: string; bidderId: string; bid: AuctionBid }
  | { type: 'auction-lot-rank-changed'; date: string; lotId: string; rank: number; bidderId: string; }
  | { type: 'auction-lot-lead-gained'; date: string; lotId: string; rank: number; bidderId: string; }
  | { type: 'auction-lot-lead-bid-changed'; date: string; lotId: string; bid: AuctionBid; }
  | { type: 'auction-lot-lead-lost'; date: string; lotId: string; bidderId: string; }
  | { type: 'auction-revised'; date: string; };

export type FieldSource =
  | { type: 'definition'; key: string }
  | { type: 'linked-exchange-definition-field'; fieldId: string; exchangeDefId: string }
  | { type: 'formula'; formula: string }
  | { type: 'reply'; role: string; key: string }
  | { type: 'parent-section-currency'; };

export enum CollationType {
  COMBINED = 'combined',
  SPLIT_BY_USER = 'splitByUser',
}

export type StageResponseTag = `stage:${string}`;

export type ResponseTag =
 | StageResponseTag;

export type FieldConfig<TSource extends FieldSource = FieldSource> = {
  _id: string;
  label?: string | null;
  type: FieldType;
  required?: boolean;
  source: TSource;
  fieldset?: string;
  collationType?: CollationType;
  visibility?: {
    type: 'has-role';
    params: { role: string };
  };
  isHidden?: boolean;
  decimalPlaces?: number;
  responseTags?: ResponseTag[];
};

export type DefinitionFieldConfig = FieldConfig<Extract<FieldSource, { type: 'definition' }>>;
export type LinkedFieldConfig = FieldConfig<Extract<FieldSource, { type: 'linked-exchange-definition-field' }>>;
export type FormulaFieldConfig = FieldConfig<Extract<FieldSource, { type: 'formula' }>>;
export type ReplyFieldConfig = FieldConfig<Extract<FieldSource, { type: 'reply' }>>;

/**
 * Ideally we could start using this everywhere
 */
type ExchangeDefinition2<
  TType extends ExchangeType = ExchangeType,
  TFields extends Record<string, FieldConfig> = Record<string, FieldConfig>,
  TValues = Record<string, unknown>,
> = {
  /**
   * UUID
   */
  _id: string;
  /**
   * The type of the exchange
   */
  type: TType;
  /**
   * The parent section's ID
   */
  sectionId: string;
  /**
   * When this value is undefined, it means the exchange belongs to _all_ stages
   */
  stages?: StageId[];
  /**
   * When provided, this configures the exchange's lock. This key will be undefined
   * when the exchange has no lock.
   */
  locking?: Lock;
  /**
   * A configuration object for all fields
   */
  fields: TFields;
  /**
   * Whether the exchange definition has been published
   */
  isLive?: boolean;
  /**
   * Whether the exchange definition has been marked obsolete
   */
  isObsolete?: boolean;
} & TValues;

export type AnyExchangeDefinition = ExchangeDefinition2;

export type AuctionExchangeFields = {
  description: {
    _id: 'description';
    type: FieldType.STRING;
    required: true;
    source: {
      type: 'definition';
      key: 'description';
    };
  };
  quantity: {
    _id: 'quantity';
    type: FieldType.NUMBER;
    required: true;
    source: {
      type: 'definition';
      key: 'quantity';
    };
  };
  unit: {
    _id: 'unit';
    type: FieldType.STRING;
    required: true;
    source: {
      type: 'definition';
      key: 'unit';
    };
  };
  price: {
    _id: 'price';
    type: FieldType.PRICE;
    required: true;
    source: {
      type: 'reply';
      role: 'submitter';
      key: 'price';
    };
    decimalPlaces: number;
  };
};

export type LinkedAuctionExchangeFields = {
  description: {
    _id: 'description';
    type: FieldType.STRING;
    required: true;
    source: {
      type: 'linked-exchange-definition-field';
      fieldId: 'description';
      exchangeDefId: string;
    };
  };
  quantity: {
    _id: 'quantity';
    type: FieldType.NUMBER;
    required: true;
    source: {
      type: 'linked-exchange-definition-field';
      fieldId: 'quantity';
      exchangeDefId: string;
    };
  };
  unit: {
    _id: 'unit';
    type: FieldType.STRING;
    required: true;
    source: {
      type: 'linked-exchange-definition-field';
      fieldId: 'unit';
      exchangeDefId: string;
    };
  };
} & Omit<AuctionExchangeFields, 'description' | 'quantity' | 'unit'>;

export type AuctionExchangeDefinitionFieldValues<Scope extends AnyScope = Live> = {
  description: string;
  quantity: Scope extends Draft ? number | null : number;
  unit: string;
};

export type StandaloneAuctionLineItemExchangeDefinition<Scope extends AnyScope = Live> = {
  type: ExchangeType.AUCTION_LINE_ITEM;
  fields: AuctionExchangeFields;
} & ExchangeDefinitionBase & AuctionExchangeDefinitionFieldValues<Scope>;

export type LinkedAuctionLineItemExchangeDefinition = {
  type: ExchangeType.AUCTION_LINE_ITEM;
  fields: LinkedAuctionExchangeFields;
  linkedExchangeDefId: string;
} & ExchangeDefinitionBase;

export type LineItemExchangeFields = {
  description: {
    _id: 'description';
    label?: string | null;
    type: FieldType.STRING;
    required: true;
    source: {
      type: 'definition';
      key: 'description';
    };
  };
  quantity?: {
    _id: 'quantity';
    type: FieldType.NUMBER;
    required: true;
    source: {
      type: 'definition';
      key: 'quantity';
    };
  };
  'quantity:submitter'?: {
    _id: 'quantity:submitter';
    type: FieldType.NUMBER;
    required: true;
    source: {
      type: 'reply';
      role: 'submitter';
      key: 'quantity:submitter';
    };
  };
  unit?: {
    _id: 'unit';
    type: FieldType.STRING;
    required: true;
    source: {
      type: 'definition';
      key: 'unit';
    };
  };
  'unit:submitter'?: {
    _id: 'unit:submitter';
    type: FieldType.STRING;
    required: true;
    source: {
      type: 'reply';
      role: 'submitter';
      key: 'unit:submitter';
    };
  };
  currency: {
    _id: 'currency';
    type: FieldType.STRING;
    required: true;
    source: {
      type: 'parent-section-currency';
    };
  };
  evaluatorFieldCurrency?: {
    _id: 'evaluatorFieldCurrency';
    type: FieldType.STRING;
    required: true;
    source: {
      type: 'definition';
      key: 'evaluatorFieldCurrency';
    };
  };
  totalCost: {
    _id: 'totalCost';
    type: FieldType.PRICE;
    required: true;
    source: {
      type: 'formula';
      formula: string;
    }
  }
  targetPrice?: {
    _id: 'targetPrice';
    type: FieldType.PRICE;
    source: {
      type: 'definition';
      key: 'targetPrice';
    };
    visibility: {
      type: 'has-role';
      params: {
        role: 'evaluator',
      };
    };
    decimalPlaces: number;
  };
  price: {
    _id: 'price';
    type: FieldType.PRICE;
    required: true;
    source: {
      type: 'reply';
      role: 'submitter';
      key: 'price';
    };
    decimalPlaces: number;
  };
  'leadTime:submitter'?: {
    _id: 'leadTime:submitter';
    type: FieldType.NUMBER;
    required: true;
    source: {
      type: 'reply';
      role: 'submitter';
      key: 'leadTime:submitter';
    };
  };
  'deliveryDate:evaluator'?: {
    _id: 'deliveryDate:evaluator';
    type: FieldType.DATE;
    required: true;
    source: {
      type: 'definition';
      key: 'deliveryDate:evaluator';
    };
  };
  'deliveryDate:submitter'?: {
    _id: 'deliveryDate:submitter';
    type: FieldType.BOOLEAN | FieldType.DATE;
    required: true;
    source: {
      type: 'reply';
      role: 'submitter';
      key: 'deliveryDate:submitter';
    };
  };
  'manufacturer:evaluator'?: {
    _id: 'manufacturer:evaluator';
    type: FieldType.STRING;
    required: true;
    source: {
      type: 'definition';
      key: 'manufacturer:evaluator';
    };
  };
  'manufacturer:submitter'?: {
    _id: 'manufacturer:submitter';
    type: FieldType.STRING;
    required: true;
    source: {
      type: 'reply';
      role: 'submitter';
      key: 'manufacturer:submitter';
    };
  };
  'partNumber:evaluator'?: {
    _id: 'partNumber:evaluator';
    type: FieldType.STRING;
    required: true;
    source: {
      type: 'definition';
      key: 'partNumber:evaluator';
    };
  };
  'partNumber:submitter'?: {
    _id: 'partNumber:submitter';
    type: FieldType.STRING;
    required: true;
    source: {
      type: 'reply';
      role: 'submitter';
      key: 'partNumber:submitter';
    };
  };
  unspscCode?: {
    _id: 'unspscCode';
    type: FieldType.UNSPSC_CODE;
    required: true;
    source: {
      type: 'definition';
      key: 'unspscCode';
    };
  };
};

export type LineItemDefinitionFieldConfig = Extract<
  LineItemExchangeFields[keyof LineItemExchangeFields],
  { source: { type: 'definition' } }
>;

export type LineItemFormulaFieldConfig = Extract<
  LineItemExchangeFields[keyof LineItemExchangeFields],
  { source: { type: 'formula' } }
>;

export type LineItemReplyFieldConfig = Extract<
  LineItemExchangeFields[keyof LineItemExchangeFields],
  { source: { type: 'reply' } }
>;

export type LineItemExchangeDefinitionFieldValues = {
  description: string;
  quantity: number | null;
  unit: string;
  /**
   * The total cost computed based on the formula
   */
  totalCost: number;
  /**
   * The currency of buyer-provided price fields.
   */
  evaluatorFieldCurrency?: string | null;
  /**
   * The target price must be hidden from suppliers at all times.
   */
  targetPrice?: number | null;
  /**
   * The delivery date set by the buyer
   */
  'deliveryDate:evaluator'?: Date | null;
  /**
   * The delivery date set by the supplier
   */
  'deliveryDate:submitter'?: Date | boolean | null;
  /**
   * The manufacturer set by the buyer
   */
  'manufacturer:evaluator'?: string | null;
  /**
   * The manufacturer set by the supplier
   */
  'manufacturer:submitter'?: string | null;
  /**
   * The part number set by the buyer
   */
  'partNumber:evaluator'?: string | null;
  /**
   * The part number set by the supplier
   */
  'partNumber:submitter'?: string | null;
  unspscCode?: string | null;
  /**
   * The full product or service corresponding to the unspscCode.
   * Only client-side.
   */
  productOrService?: ProductTag | null;
};

export type LineItemExchangeDefinition2 = ExchangeDefinition2<
  ExchangeType.LINE_ITEM,
  LineItemExchangeFields,
  LineItemExchangeDefinitionFieldValues
>;

export type EvaluationExchangeFields = {
  description: {
    _id: 'description';
    type: FieldType.STRING;
    required: true;
    source: {
      type: 'definition';
      key: 'description';
    };
  };
  maxPoints: {
    _id: 'maxPoints';
    type: FieldType.NUMBER;
    required: true;
    source: {
      type: 'definition';
      key: 'maxPoints';
    };
  };
  weight: {
    _id: 'weight';
    type: FieldType.NUMBER;
    required: true;
    source: {
      type: 'definition';
      key: 'weight';
    };
  };
  score: {
    _id: 'score';
    type: FieldType.SCORE_POINTS;
    required: true;
    collationType: CollationType;
    source: {
      type: 'reply';
      role: 'submitter';
      key: 'points';
    };
  };
};

export interface StageApprovalExchangeFields {
  recipientIds: {
    _id: 'recipientIds';
    type: 'array';
    required: true;
    source: {
      type: 'definition';
      key: 'recipientIds';
    };
  };
  message: {
    _id: 'message';
    type: FieldType.STRING;
    required: true;
    source: {
      type: 'definition';
      key: 'message';
    };
  };
  attachments: {
    _id: 'attachments';
    type: 'array';
    source: {
      type: 'definition';
      key: 'attachments';
    };
  };
  approverIds: {
    _id: 'approverIds';
    type: 'array';
    required: true;
    source: {
      type: 'definition';
      key: 'approverIds';
    };
  };
}

export interface LinkedEvaluationExchangeFields extends Omit<EvaluationExchangeFields, 'description'> {
  description: {
    _id: 'description';
    type: FieldType.STRING;
    required: true;
    source: {
      type: 'linked-exchange-definition-field';
      fieldId: string;
      exchangeDefId: string;
    };
  };
}

export type EvaluationExchangeDefinitionFieldValues = {
  description: string;
  maxPoints: number;
  weight: number;
};

export type ExchangeDefinitionBase = {
  _id: string;
  type: ExchangeType;
  stages?: StageId[];
  isObsolete?: boolean;
  locking?: Lock;
  creatorId?: CompanyId;
  publisherId?: CompanyId;
  isLive?: boolean;
  liveVersion?: ExchangeDefinition;
  sectionId?: string;
  pendingActions?: Action[];
  user?: UserMinimized;
  fields?: Record<string, FieldConfig>;
};

export type LineItemExchangeDefinition = {
  type: ExchangeType.LINE_ITEM;
  fields: LineItemExchangeFields;
  orderedFieldIds?: string[];
} & ExchangeDefinitionBase & LineItemExchangeDefinitionFieldValues;

export type StageApprovalExchangeDefinition = ExchangeDefinitionBase & {
  type: ExchangeType.STAGE_APPROVAL;
  date: Date;
  /**
   * `stageId` is not set for stage approvals on draft requests.
   */
  stageId?: string;
  recipientIds: string[];
  message: string;
  attachments: Attachment[];
  approverIds: string[];
};

export type UnlinkedEvaluationCriterionExchangeDefinition = {
  type: ExchangeType.EVALUATION_CRITERION;
  fields: EvaluationExchangeFields;
} & ExchangeDefinitionBase & EvaluationExchangeDefinitionFieldValues;

export type LinkedEvaluationCriterionExchangeDefinition = {
  type: ExchangeType.EVALUATION_CRITERION;
  linkedExchangeDefId: string;
  fields: LinkedEvaluationExchangeFields;
} & ExchangeDefinitionBase & EvaluationExchangeDefinitionFieldValues;

export type EvaluationCriterionExchangeDefinition =
  | UnlinkedEvaluationCriterionExchangeDefinition
  | LinkedEvaluationCriterionExchangeDefinition;
export interface TermsExchangeDefinition extends ExchangeDefinitionBase {
  type: ExchangeType.TERMS;
  description: string;
}

export interface InclusionsExchangeDefinition extends ExchangeDefinitionBase {
  type: ExchangeType.INCLUSIONS;
  description: string;
  option: InclusionOption | null;
}

export interface FeesExchangeDefinition extends ExchangeDefinitionBase {
  type: ExchangeType.FEES;
  vesselId?: string;
  description: string;
  feeType: FeeType;
}

export interface HirePeriodExchangeDefinition extends ExchangeDefinitionBase {
  type: ExchangeType.HIRE_PERIOD;
  hirePeriodId: string;
  intervalType: 'Firm' | 'Option';
  amount: number;
  unit: HirePeriodUnit;
  quantity: number;
}

export type DocumentExchangeDefinition = ExchangeDefinitionBase & {
  supertype: DocumentExchangeSupertype;
  type: DocumentExchangeType;
  category: string; // description
  attachments: Attachment[];
};

export type CurrencyExchangeDefinition = ExchangeDefinitionBase & {
  type: ExchangeType.CURRENCY;
  currencies: string[];
  isFixed?: boolean;
};

export interface PaymentStageExchangeDefinition extends ExchangeDefinitionBase {
  type: ExchangeType.PAYMENT_STAGE;
  milestone: string;
  percentage: number;
  days: string;
}

export interface DeliveryAddressExchangeDefinition extends ExchangeDefinitionBase {
  type: ExchangeType.DELIVERY_ADDRESS;
  deliveryTo: Address;
}

export interface DeliveryDeadlineExchangeDefinition extends ExchangeDefinitionBase {
  type: ExchangeType.DELIVERY_DEADLINE;
  deliveryBy: string;
}

export interface IncotermsExchangeDefinition extends ExchangeDefinitionBase {
  type: ExchangeType.INCOTERMS;
  incoterms: string;
}

export interface ChatExchangeDefinition extends ExchangeDefinitionBase {
  type: ExchangeType.CHAT;
  name: string;
  source?: ExchangeSource;
}

export interface BulletinExchangeDefinition extends ExchangeDefinitionBase {
  type: ExchangeType.BULLETIN;
  message: string;
  createdAt?: Date;
  attachments?: Attachment[];
}

export interface InternalDocumentExchangeDefinition extends ExchangeDefinitionBase {
  type: ExchangeType.INTERNAL_DOCUMENT;
  name: string;
  attachments: Attachment[];
  comment: string;
}

export interface ChatNoReceiverUploadExchangeDefinition extends ExchangeDefinitionBase {
  type: ExchangeType.CHAT_NO_RECEIVER_UPLOAD;
  name: string;
}

export interface ChatOnlyReceiverUploadExchangeDefinition extends ExchangeDefinitionBase {
  type: ExchangeType.CHAT_ONLY_RECEIVER_UPLOAD;
  name: string;
}

export interface ClarificationExchangeDefinition extends ExchangeDefinitionBase {
  type: ExchangeType.CLARIFICATION;
  name: string;
}

export interface QuestionExchangeDefinitionBase extends ExchangeDefinitionBase {
  type: ExchangeType.QUESTION;
  description: string;
  isRequired: boolean;
}

export interface ShortTextQuestionExchangeDefinition<Scope extends AnyScope = Live> extends QuestionExchangeDefinitionBase {
  questionType: QuestionType.SHORT_TEXT;
  schema?: ShortTextSchema<Scope>,
}

export interface LongTextQuestionExchangeDefinition extends QuestionExchangeDefinitionBase {
  questionType: QuestionType.LONG_TEXT;
}

export interface MultipleChoiceQuestionExchangeDefinition extends QuestionExchangeDefinitionBase {
  questionType: QuestionType.MULTIPLE_CHOICE;
  options: string[];
  allowCustomOption?: boolean; // This is the equivalent of the "Other" option in the UI
}

export interface CheckboxesQuestionExchangeDefinition extends QuestionExchangeDefinitionBase {
  questionType: QuestionType.CHECKBOXES;
  options: string[];
  allowCustomOption?: boolean; // This is the equivalent of the "Other" option in the UI
}

export interface PriceQuestionExchangeDefinition extends QuestionExchangeDefinitionBase {
  questionType: QuestionType.PRICE;
  /**
   * This limits the currencies that are available to be selected.
   */
  currencies: string[] | null;
}

export interface AddressQuestionExchangeDefinition extends QuestionExchangeDefinitionBase {
  questionType: QuestionType.ADDRESS;
  visibleFields: QuestionAddressField[];
}

export interface DateTimeQuestionExchangeDefinition<Scope extends AnyScope = Live> extends QuestionExchangeDefinitionBase {
  questionType: QuestionType.DATE_TIME;
  format: Scope extends Draft ? DraftQuestionFormat : LiveQuestionFormat;
}

export enum PredefinedQuestionOption {
  YES = '__yes__',
  NO = '__no__',
  UPLOAD_DOCUMENT = '__upload-document__',
  CANNOT_PROVIDE = '__cannot-provide__',
}

export type YesNoOption = PredefinedQuestionOption.YES | PredefinedQuestionOption.NO;
export type UploadCannotProvideOption = PredefinedQuestionOption.UPLOAD_DOCUMENT | PredefinedQuestionOption.CANNOT_PROVIDE;

export interface DocumentQuestionExchangeDefinition extends QuestionExchangeDefinitionBase {
  questionType: QuestionType.DOCUMENT;
  options: (YesNoOption | UploadCannotProvideOption)[];
  allowCustomOption: boolean; // This is the equivalent of the "Other" option in the UI
  requireDocument: boolean;
  requireExpiry: boolean;
}

export interface GridQuestionColumn {
  id: string;
  name: string;
  type: FieldType;
}

export interface GridQuestionExchangeDefinition extends QuestionExchangeDefinitionBase {
  questionType: QuestionType.GRID;
  columns: GridQuestionColumn[];
  rowsConfig: {
    isCustom: boolean;
    min?: number;
    max?: number;
  };
  currencies?: string[];
}

export interface YesNoQuestionExchangeDefinition extends QuestionExchangeDefinitionBase {
  questionType: QuestionType.YES_NO;
  options: YesNoOption[];
  allowCustomOption: boolean; // This is the equivalent of the "Other" option in the UI
  requireMoreInformationOn: YesNoOption[]; // The options that require more information
}

export interface AuctionTermsExchangeDefinition extends ExchangeDefinitionBase {
  type: ExchangeType.AUCTION_TERMS;
  text: string;
}

export interface AuctionInformationExchangeDefinition extends ExchangeDefinitionBase {
  type: ExchangeType.AUCTION_INFORMATION;
  text: string;
}

export type AuctionLineItemExchangeDefinition<Scope extends AnyScope = Live> =
  | StandaloneAuctionLineItemExchangeDefinition<Scope>
  | LinkedAuctionLineItemExchangeDefinition;

export type OptionQuestionExchangeDefinition =
  | MultipleChoiceQuestionExchangeDefinition
  | CheckboxesQuestionExchangeDefinition;

export type QuestionExchangeDefinition<Scope extends AnyScope = Live> =
  | ShortTextQuestionExchangeDefinition<Scope>
  | LongTextQuestionExchangeDefinition
  | MultipleChoiceQuestionExchangeDefinition
  | CheckboxesQuestionExchangeDefinition
  | PriceQuestionExchangeDefinition
  | AddressQuestionExchangeDefinition
  | DateTimeQuestionExchangeDefinition<Scope>
  | DocumentQuestionExchangeDefinition
  | GridQuestionExchangeDefinition
  | YesNoQuestionExchangeDefinition;

export type ExchangeDefinition<Scope extends AnyScope = Live> =
  | TermsExchangeDefinition
  | InclusionsExchangeDefinition
  | FeesExchangeDefinition
  | HirePeriodExchangeDefinition
  | DocumentExchangeDefinition
  | CurrencyExchangeDefinition
  | LineItemExchangeDefinition
  | EvaluationCriterionExchangeDefinition
  | StageApprovalExchangeDefinition
  | LinkedEvaluationCriterionExchangeDefinition
  | PaymentStageExchangeDefinition
  | DeliveryAddressExchangeDefinition
  | DeliveryDeadlineExchangeDefinition
  | IncotermsExchangeDefinition
  | ChatExchangeDefinition
  | ChatNoReceiverUploadExchangeDefinition
  | ChatOnlyReceiverUploadExchangeDefinition
  | InternalDocumentExchangeDefinition
  | BulletinExchangeDefinition
  | ClarificationExchangeDefinition
  | QuestionExchangeDefinition<Scope>
  | AuctionTermsExchangeDefinition
  | AuctionInformationExchangeDefinition
  | AuctionLineItemExchangeDefinition<Scope>
  | ContractDocumentExchangeDefinition
  | LegacyContractExchangeDefinition
  | ApprovalExchangeDefinition;

export type ExchangeDef<T extends ExchangeType> = Extract<ExchangeDefinition, { type: T }>;

export type ExchangeDefByType = Partial<{
  [P in ExchangeType]: ExchangeDef<P>;
}>;

export type ExchangeDefsByType = Partial<{
  [P in ExchangeType]: ExchangeDef<P>[];
}>;

export type VesselPricingExchangeDefinition =
  TermsExchangeDefinition
  | InclusionsExchangeDefinition
  | HirePeriodExchangeDefinition
  | FeesExchangeDefinition
  | CurrencyExchangeDefinition;

export type LineItemPricingExchangeDefinition =
  | LineItemExchangeDefinition
  | CurrencyExchangeDefinition;

export type DeliveryExchangeDefinition =
  DeliveryAddressExchangeDefinition
  | DeliveryDeadlineExchangeDefinition
  | IncotermsExchangeDefinition;

export type PaymentExchangeDefinition =
  CurrencyExchangeDefinition
  | PaymentStageExchangeDefinition;

export interface Action {
  value: ActionType;
  attachments?: Attachment[];
  companyId: string;
  user: User;
  date: Date;
  comment?: string;

  // For delivery exchanges
  incoterms?: string;
  deliveryBy?: string;
  deliveryTo?: object;

  // For currency exchange
  currency?: string;

  // For clarification exchange
  bulletinId?: string;

  // For fee exchange
  vessels?: {
    [key: string]:
      | { rate: number | null; expanded?: boolean }
      | { rates: Array<number | null>; expanded?: boolean };
  };

  // For line items exchange
  price?: number;
  'leadTime:submitter'?: number;
  submissionMethod?: 'app' | 'excel';

  // For inclusion exchange
  option?: InclusionOption;

  // For evaluation criterion exchange
  points?: number;

  // For question exchange
  response?: QuestionResponse;

  // For stage approval exchange
  isApproved?: boolean;

  // For "revise" actions
  previousValue?: ExchangeDefinition;
  currentValue?: ExchangeDefinition;

  // For "agree" actions (contract exchange)
  signers?: { _id: string; name: string }[];

  payload?: {
    extendedStageId?: string;
    extensionExpiry?: ExtensionExpiry;
  };
}

export enum AuctionType {
  ENGLISH = 'english',
  DUTCH = 'dutch',
  JAPANESE = 'japanese',
}

export interface AuctionRules<Scope extends AnyScope = Live> {
  type: AuctionType;
  bidFeedback: AuctionBidFeedbackType;
  minimumReduction: {
    type: MinimumReductionType,
    value: Scope extends Draft ? number | null : number,
  },
  tieBids: boolean;
  ceilingPrice: Scope extends Draft
    ? { amount: number | null } | null
    : { amount: number } | null;
  /**
   * The duration of the auction in minutes.
   */
  duration: Scope extends Draft ? number | null : number;
  autoExtension: Scope extends Draft
    ? {
      trigger: AutoExtensionTrigger;
      timeToRespond: number | null;
    } | null
    : {
      trigger: AutoExtensionTrigger;
      timeToRespond: number;
    } | null;
  preBids: boolean;
  currency: string;
  decimalPlaces: number;
}

export enum PageType {
  CHAT = 'chat',
  EVALUATION = 'evaluation',
  AUCTION = 'auction',
  CONTRACT = 'contract',
}

export type BasePage = {
  _id: string;
  type?: PageType;
  name: string;
  sections: string[];
  isHiddenWhileEditing?: boolean;
  isHiddenInSupplierTable?: boolean;
  isLive?: boolean;
};

export type ChatPage = BasePage & {
  type: PageType.CHAT;
};

export type EvaluationPage = BasePage & {
  type: PageType.EVALUATION;
  linkedPageId?: string;
  weight: number;
};

export type AuctionPage = BasePage & {
  type: PageType.AUCTION;
};

export type Page =
  | BasePage
  | ChatPage
  | AuctionPage
  | EvaluationPage;
export interface CompanyMinimized {
  _id: CompanyId;
  name: string;
  isPending?: boolean;
  role?: string;
}

export interface Company {
  _id: CompanyId;
  users: User[];
  isPending?: boolean;
  email?: string;
  company: CompanyMinimized;
}

export interface RfqCompany {
  readonly _id: string;
  readonly name: string;
  readonly users: Array<User>;
  getUser(userId: string): User;
  isOwner(userId: string): boolean;
  canEditPage(pageId: string, userId: string): boolean;
  canReadPage(pageId: string, userId: string): boolean;
  canReadSection(sectionId: string, userId: string): boolean;
}

export interface Section {
  _id: string;
  type: SectionType;
  name: string;
  description?: string;
  docXDefs: ExchangeDefinition[];
  providedBy?: ExchangeProvider;
  stages?: StageId[];
  locking?: Lock;
  isLive?: boolean;
  lotIds?: string[] | null;
  isObsolete?: boolean;
}

export interface InternalDocumentSection extends Section {
  type: SectionType.INTERNAL_DOCUMENT;
  histories?: {
    [key in string]: Action[];
  };
  liveVersion?: InternalDocumentSection;
}

export interface VesselPricingSection extends Section {
  type: SectionType.VESSEL_PRICING;
  hirePeriods: HirePeriod[];
  docXDefs: VesselPricingExchangeDefinition[];
  allowSuppliersToAddFees: boolean;
  allowSuppliersToAddAdditionalTerms: boolean;
  allowSuppliersToAddInclusionsAndExclusions: boolean;
  liveVersion?: VesselPricingSection;
}

export interface DeliverySection extends Section {
  type: SectionType.DELIVERY;
  docXDefs: DeliveryExchangeDefinition[];
}

export enum FieldScope {
  ALL_PRICE_FIELDS = 'allPriceFields',
  ALL_FIELDS = 'allFields',
  CUSTOM = 'custom',
}

export interface LineItemsSection extends Section {
  type: SectionType.LINE_ITEMS;
  docXDefs: LineItemExchangeDefinition[];
  liveVersion?: LineItemsSection;
  responseTagConfig?: {
    fieldScope: FieldScope;
    tags: ResponseTag[];
  };
}

export interface EvaluationSection extends Section {
  type: SectionType.EVALUATION;
  docXDefs: EvaluationCriterionExchangeDefinition[];
  weight: number;
  liveVersion?: EvaluationSection;
}

export interface StageApprovalSection extends Section {
  type: SectionType.STAGE_APPROVAL;
  docXDefs: StageApprovalExchangeDefinition[];
  liveVersion?: StageApprovalSection;
}

export interface LinkedEvaluationSection extends Omit<EvaluationSection, 'docXDefs'> {
  /**
   * The ID of the details section that this section is linked to.
   */
  linkedSectionId: string;
  docXDefs: LinkedEvaluationCriterionExchangeDefinition[];
}

export type RfxSectionBase = {
  type: SectionType;
  _id: string;
  name: string;
  description?: string;
  exchangeDefIds: ExchangeId[];
  providedBy?: ExchangeProvider;
  stages?: StageId[];
  locking?: Lock;
  isLive?: boolean;
  responseTagConfig?: {
    fieldScope: FieldScope;
    tags: ResponseTag[];
  };
  lotIds?: string[];
  isObsolete?: boolean;
};

export type RfxVesselPricingSection = Compact<Modify<RfxSectionBase, {
  type: SectionType.VESSEL_PRICING;
  hirePeriodIds: HirePeriod['_id'][];
  allowSuppliersToAddFees: boolean;
  allowSuppliersToAddAdditionalTerms: boolean;
  allowSuppliersToAddInclusionsAndExclusions: boolean;
  liveVersion?: RfxVesselPricingSection;
}>>;

export type RfxEvaluationSection = Modify<RfxSectionBase, {
  type: SectionType.EVALUATION;
  weight: number;
  liveVersion?: RfxEvaluationSection;
}>;

export type RfxAuctionLineItemsSection<Scope extends AnyScope = Live> = Modify<RfxSectionBase, {
  type: SectionType.AUCTION_LINE_ITEMS;
  auctionRules: AuctionRules<Scope>;
  liveVersion?: RfxAuctionLineItemsSection<Live>;
}>;

export type RfxOtherSection = Modify<RfxSectionBase, {
  type: Exclude<SectionType, SectionType.VESSEL_PRICING | SectionType.EVALUATION>;
  liveVersion?: RfxOtherSection;
}>;

export type RfxSection<Scope extends AnyScope = Live> =
  | RfxVesselPricingSection
  | RfxEvaluationSection
  | RfxAuctionLineItemsSection<Scope>
  | RfxOtherSection;

export interface PaymentSection extends Section {
  type: SectionType.PAYMENT;
  docXDefs: LineItemExchangeDefinition[];
}

export interface DocumentSection extends Section {
  type: SectionType.DOCUMENT;
  docXDefs: DocumentExchangeDefinition[];
  liveVersion?: DocumentSection;
}

export interface ChatSection extends Section {
  type: SectionType.CHAT;
  docXDefs: ChatExchangeDefinition[];
}

export interface QuestionSection extends Section {
  type: SectionType.QUESTION;
  docXDefs: QuestionExchangeDefinition[];
  liveVersion?: QuestionSection;
}

export interface AuctionTermsSection extends Section {
  type: SectionType.AUCTION_TERMS;
  docXDefs: (AuctionTermsExchangeDefinition | AuctionInformationExchangeDefinition)[];
  liveVersion?: AuctionTermsSection;
}

export interface AuctionLineItemsSection<Scope extends AnyScope = Live> extends Omit<Section, 'docXDefs'> {
  type: SectionType.AUCTION_LINE_ITEMS;
  docXDefs: (CurrencyExchangeDefinition | AuctionLineItemExchangeDefinition<Scope>)[];
  auctionRules: AuctionRules<Scope>;
  liveVersion?: AuctionLineItemsSection<Live>;
}

export interface Address {
  lineOne?: string;
  lineTwo?: string;
  postcode?: string;
  state?: string;
  city?: string;
  country?: string;
}

export interface Port {
  port: string;
}

export interface Vessel {
  _id: string;
  name: string;
  fromDate: string;
  toDate: string;
  redeliveryLocation: Port;
  deliveryLocation: Port;
  sameRedeliveryLocationAsDelivery: boolean;
  extendedStageId?: string;
}

export type Asset = Vessel;

export type LockState =
  { isLocked: true } |
  { isLocked: false; unlockedBy: UserMinimized; unlockedDate: number };

export interface BidSection {
  _id: string;
  type: SectionType;
  extraExchangeDefs: ExchangeDefinition[];
  assets?: Asset[];
  locking?: LockState;
  pendingActions: {
    [key: string]: Action;
  };
  histories: {
    [key: string]: History;
  };
  status: 'empty' | 'inProgress' | 'blocked' | 'complete';
}

export interface Meta {
  status: RfqStatus;
  company: CompanyMinimized;
  createdBy: UserMinimized;
  createdAt: string;
  issueDate: string;
  issuedBy?: UserMinimized;
  numRevisions: number;
  awarded?: RequestOutcomeMeta;
  closed?: RequestOutcomeMeta;
  lastEdited: {
    date: Date;
    user: UserMinimized
  };
}

export interface TriggeredSystemEvents {
  stageDeadlineApproached?: Record<StageId, true>;
  stageDeadlineExpired?: Record<StageId, Date>;
  bidDeadlineExpired?: Date;
  emailsSent?: Record<string, Record<CompanyId, Record<User['_id'], Date[]>>>;
}

export interface RequestOutcomeMeta {
  user: UserMinimized;
  message: string;
  date: string;
  value: boolean;
  attachments: Attachment[];
}

export interface ApproximateTotal {
  amount: number;
  currencyCode: string;
}

export interface Details {
  senders: Sender[];
  subject: string;
  reference: string;
  description: string;
  pqqRequestId: string;
  attachments: Attachment[];
  stages: Stage[];
  sections: Section[];
  pages: Page[];
  settings: {
    isEvaluationEnabled: boolean;
    scoringType: ScoringType;
    isPubliclyAvailable: boolean;
  };
  pqqRequestSnapshot?: any;
  isEnhancedListing: boolean;
  /**
   * @deprecated
   */
  approximateTotal?: ApproximateTotal;
  autoReferenceNumber: number;
  productsAndServices: ProductTag[];
}

// FIXME: type this more accurately
export type BidHistory = any[];

export interface BidStage {
  stageId: string;
  entranceDate: Date;
  intention?: boolean;
  intentionDate?: Date;
}

export type BidStatusHistory = {
  status: BidStatus;
  timestamp: Date;
}[];

export interface Bid {
  status: BidStatus;
  statusHistory: BidStatusHistory;
  isOpen: boolean;
  stage: StageId;
  history: BidHistory;
  sections: BidSection[];
  stages: {
    [key: string]: BidStage;
  };
  approvals: {
    [key: string]: boolean;
  };
  pricing: Record<string, number>;
  meta: {
    noIntention: {
      user: UserMinimized;
      reason: string;
      timestamp: Date;
    };
    bidWithdrawn: {
      user: UserMinimized;
      reason: string;
      timestamp: Date;
      stageId?: string;
    };
  };
  pendingExtraExchangeDefs?: any[];
  /** @deprecated */
  sent?: any;
}

export enum ChatType {
  WITHOUT_RECEIVER_DOCS = 'withoutReceiverDocs',
  WITH_DOCS = 'withDocs',
  // WITH_LOCKED_RECEIVER_DOCS = 'withLockedReceiverDocs', disabled until locking is implemented
}

/** @deprecated */
export enum SenderType {
  CLIENT = 'client',
  BUYER = 'buyer',
}

export enum CollaboratorInviteStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}

export enum ApprovalResponseStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export interface Sender extends Company {
  role: string; // used for collaborators roles
  inviteStatus?: CollaboratorInviteStatus;
  inviter?: UserMinimized;
  inviterCompany?: CompanyMinimized;
  /** @deprecated */
  type?: SenderType; // previous used for 'client' type company
}

export interface Recipient extends Company {
  bid: Bid;
  issueDate: string;
  source?: string;
}

export interface RfqEnvelope {
  _id: string;
  maxComplexity?: number;
  maxExchangeDefCount?: number;
  meta: Meta;
  triggeredSystemEvents: TriggeredSystemEvents;
  recipients: Recipient[];
  senders: Sender[];
  draft: Details;
  live: Details;
  events?: RfqEvent[];
}
export interface RfxEnvelope {
  _id: string;
  meta: Meta;
  recipients: Recipient[];
  senders: Sender[];
  draft: Details;
  live: Details;
}
export interface Approval {
  userId: string;
  status: 'pending' | 'approved' | 'rejected';
  message: string;
  date?: string;
}

export interface ApprovalRequest {
  stageId: StageId;
  date: string;
  message: string;
  attachments: Attachment[];
  recipientIds: string[];
  approvals: Approval[];
  requestedBy: UserMinimized;
}

export type VesselId = string;

export interface IntervalRatesInput {
  expanded: boolean;
  rates: number[];
}

export interface HirePeriodAnswerInput {
  vessels: Record<VesselId, IntervalRatesInput>;
}

export interface FeesAnswerInput {
  vessels: Record<VesselId, { rate: number }>;
}

export interface CurrencyAnswerInput {
  currencyCode: string;
}

export interface InclusionsAnswerInput {
  option: InclusionOption;
}

export interface LineItemAnswerInput {
  price: number;
}

export interface ExchangeCriterionAnswerInput {
  points: number;
}

export type ExchangeAnswerInput<T extends ExchangeDefinition> =
  T extends InclusionsExchangeDefinition ? InclusionsAnswerInput :
  T extends HirePeriodExchangeDefinition ? HirePeriodAnswerInput :
  T extends FeesExchangeDefinition ? FeesAnswerInput :
  T extends CurrencyExchangeDefinition ? CurrencyAnswerInput :
  T extends LineItemExchangeDefinition ? LineItemAnswerInput :
  T extends EvaluationCriterionExchangeDefinition ? ExchangeCriterionAnswerInput :
  never;

export interface ExchangeAnswer<T extends ExchangeDefinition = ExchangeDefinition> {
  exchangeDef: T;
  input: ExchangeAnswerInput<T>;
}

type HirePeriodId = string;

export interface Totals {
  firmAndFeesTotal?: number;
  grandTotal?: number;
}

export type TotalsByVesselId = {
  [key in VesselId]: {
    [key in HirePeriodId]: Totals;
  }
};

export interface RecipientCompany extends CompanyMinimized {
  email?: string;
}

export interface ActivatedRecipient {
  _id: string;
  company: RecipientCompany;
  users: UserMinimized[];
}

export type BidEvent =
  | InterestExpressedEvent
  | PendingRecipientEmailStatusUpdatedEvent
  | InvitationToTenderSentEvent
  | StageEnteredEvent
  | IntentionUpdatedEvent
  | LotIntentionUpdatedEvent
  | BidWithdrawnEvent
  | BidReopenedEvent
  | ReplySentEvent
  | ExchangesInitiatedEvent
  | ExchangeReplySentEvent
  | PricingSentEvent
  | PageApprovedEvent
  | BidAwardedEvent
  | BidRejectedEvent
  | BidReinstatedEvent
  | ExchangesChangedEvent
  | AssetsChangedEvent;

export type RfqSystemEvent =
  | StageCompletionDeadlineApproachedEvent
  | BidDeadlineExpiredEvent
  | StageDeadlineExpiredEvent
  | EmailsSentEvent
  | AuctionStartedEvent
  | AuctionLotBiddingClosedEvent;

/**
 * Union of events that are persisted in the `rfqEnvelope` events array.
 *
 * State machine events do not belong here -- see `RfxEvent`.
 */
export type RfqEvent =
  | RequestCreatedEvent
  | DetailsChangedEvent
  | SendersChangedEvent
  | CollaborationInviteAcceptedEvent
  | CollaborationInviteRejectedEvent
  | RecipientsUpdatedEvent
  | RequestSentEvent
  | RequestRevisedEvent
  | RecipientsAddedEvent
  | RecipientActivatedEvent
  | ExchangesUnlockedEvent
  | SettingsUpdatedEvent
  | LiveSettingsUpdatedEvent
  | SpendAndSavingsChangedEvent
  | RequestCurrencyChangedEvent
  | RequestAwardedEvent
  | RequestClosedEvent
  | RequestDeletedEvent
  | ApprovalRequestedEvent
  | ApprovalRequestCancelledEvent
  | ApprovalDecidedEvent
  | InternalDocumentAddedEvent
  | InternalExchangeReplySentEvent
  | TeamMembersChangedEvent
  | BidEvent
  | RfqSystemEvent
  | AuctionEndedEvent
  | AuctionLotBidSubmittedEvent
  | AuctionLotBidExtendedEvent
  | AuctionBiddingExtendedEvent
  | AuctionLotBidRetractionRequestedEvent
  | AuctionCancelledEvent
  | AuctionBiddingPausedEvent
  | AuctionBiddingResumedEvent;

export enum EventType {
  REQUEST_CREATED = 'request-created',
  DETAILS_CHANGED = 'details-changed',
  SENDERS_CHANGED = 'senders-changed',
  COLLABORATION_INVITE_ACCEPTED = 'collaboration-invite-accepted',
  COLLABORATION_INVITE_REJECTED = 'collaboration-invite-rejected',
  RECIPIENTS_UPDATED = 'recipients-updated',
  STAGE_COMPLETION_DEADLINE_APPROACHED = 'stage-completion-deadline-approached',
  BID_DEADLINE_EXPIRED = 'bid-deadline-expired',
  STAGE_DEADLINE_EXPIRED = 'stage-deadline-expired',
  EMAILS_SENT = 'emails-sent',
  REQUEST_SENT = 'request-sent',
  REQUEST_REVISED = 'request-revised',
  RECIPIENTS_ADDED = 'recipients-added',
  RECIPIENT_ACTIVATED = 'recipient-activated',
  PENDING_RECIPIENT_EMAIL_STATUS_UPDATED = 'pending-recipient-email-status-updated',
  STAGE_ENTERED = 'stage-entered',
  INTENTION_UPDATED = 'intention-updated',
  LOT_INTENTION_UPDATED = 'lot-intention-updated',
  BID_WITHDRAWN = 'bid-withdrawn',
  BID_REOPENED = 'bid-reopened',
  EXCHANGES_INITIATED = 'exchanges-initiated',
  EXCHANGE_REPLY_SENT = 'exchange-reply-sent',
  PAGE_APPROVED = 'page-approved',
  EXCHANGES_UNLOCKED = 'exchanges-unlocked',
  BID_AWARDED = 'bid-awarded',
  BID_REJECTED = 'bid-rejected',
  BID_REINSTATED = 'bid-reinstated',
  REQUEST_AWARDED = 'request-awarded',
  REQUEST_CLOSED = 'request-closed',
  REQUEST_DELETED = 'request-deleted',
  APPROVAL_REQUESTED = 'approval-requested',
  INTERNAL_DOCUMENT_ADDED = 'internal-document-added',
  INTERNAL_EXCHANGE_REPLY_SENT = 'internal-exchange-reply-sent',
  TEAM_MEMBERS_CHANGED = 'team-members-changed',
  EXCHANGES_CHANGED = 'exchanges-changed',
  ASSETS_CHANGED = 'assets-changed',
  REFERENCE_NUMBER_OFFSET_UPDATED = 'reference-number-offset-updated',
  AUCTION_STARTED = 'auction-started',
  AUCTION_ENDED = 'auction-ended',
  AUCTION_LOT_BIDDING_CLOSED = 'auction-lot-bidding-closed',
  AUCTION_LOT_BID_SUBMITTED = 'auction-lot-bid-submitted',
  AUCTION_LOT_BID_RETRACTION_REQUESTED = 'auction-lot-bid-retraction-requested',
  AUCTION_CANCELLED = 'auction-cancelled',
  AUCTION_BIDDING_PAUSED = 'auction-bidding-paused',
  AUCTION_BIDDING_RESUMED = 'auction-bidding-resumed',
  AUCTION_LOT_BIDDING_EXTENDED = 'auction-lot-bidding-extended',
  AUCTION_BIDDING_EXTENDED = 'auction-bidding-extended',
  SETTINGS_CHANGED = 'settings-changed',
  LIVE_SETTINGS_CHANGED = 'live-settings-changed',
  SPEND_AND_SAVINGS_CHANGED = 'spend-and-savings-changed',
  REQUEST_CURRENCY_CHANGED = 'request-currency-changed',
  /** @deprecated */
  INTEREST_EXPRESSED = 'interest-expressed',
  /** @deprecated */
  INVITATION_TO_TENDER_SENT = 'invitation-to-tender-sent',
  /** @deprecated */
  REPLY_SENT = 'reply-sent',
  /** @deprecated */
  PRICING_SENT = 'pricing-sent',
  /** @deprecated * */
  APPROVAL_REQUEST_CANCELLED = 'approval-request-cancelled',
  /** @deprecated * */
  APPROVAL_DECIDED = 'approval-decided',
}

export interface EventMeta {
  user: {
    _id: string;
    name: string;
  };
  timestamp: Date;
  ip: string;
}

export interface BasicEvent {
  _id: string;
  meta: EventMeta;
}

export interface Request {
  _id: string;
  events: RfqEvent[];
}

export interface RequestCreatedEvent extends BasicEvent {
  type: EventType.REQUEST_CREATED;
  companyId: string;
  company?: {
    _id: string;
    name: string;
  };
  autoReferenceNumber?: number;
  senders: Sender[];
  recipients: {
    _id: string;
    company: RecipientCompany;
    source: RecipientSource;
    users: UserMinimized[];
  }[];
  stages: Stage[];
  pages: Page[];
  sections: Section[];
  settings?: {
    isEvaluationEnabled: boolean;
    scoringType: ScoringType;
    isPubliclyAvailable: boolean;
    areLotsEnabled: boolean;
    awardScenarios: {
      requestLevelAward: boolean;
      lineLevelAward: boolean;
      lotLevelAward: boolean;
      noAward: boolean;
    };
    canSplitAwards: boolean;
  };
  isEnhancedListing: boolean;
  exchangeDefs?: ExchangeDefinition[];
  fields?: {
    subject: string;
    description: string;
    /**
     * @deprecated
     */
    approximateTotal?: ApproximateTotal;
    productsAndServices: ProductTag[],
    reference?: string;
  };
  currencyCode?: string;
  spendAndSavings?: Partial<RfxSpendAndSavings> | null;
  fromTemplateId?: string;
  lots?: Lot<Draft>[];
}

export interface DetailsChangedEvent extends BasicEvent {
  type: EventType.DETAILS_CHANGED;
  companyId: string;
  changes: DetailsChange[];
  message: string;
  isRevising: boolean;
}

export interface SendersChangedEvent extends BasicEvent {
  type: EventType.SENDERS_CHANGED;
  companyId: string;
  changes: SenderChange[];
}
export interface CollaborationInviteAcceptedEvent extends BasicEvent {
  type: EventType.COLLABORATION_INVITE_ACCEPTED;
  companyId: string;
}

export interface CollaborationInviteRejectedEvent extends BasicEvent {
  type: EventType.COLLABORATION_INVITE_REJECTED;
  companyId: string;
}

export interface TeamMembersChangedEvent extends BasicEvent {
  type: EventType.TEAM_MEMBERS_CHANGED;
  companyId: string;
  changes: TeamMembersChange[];
}

export interface RecipientsUpdatedEvent extends BasicEvent {
  type: EventType.RECIPIENTS_UPDATED;
  companyId: string;
  recipients: RecipientCompany[];
  /**
   * Old version of this event and templates don't have this property
   */
  usersByRecipientId?: {
    [key in string]: (UserMinimized & { isOwner?: boolean })[]
  };
  /**
   * Old version of this event did not have this property
   */
  sourceByRecipientId?: {
    [key in string]: RecipientSource;
  };
}

export interface StageCompletionDeadlineApproachedEvent extends BasicEvent {
  type: EventType.STAGE_COMPLETION_DEADLINE_APPROACHED;
  stageId: string;
}

export interface BidDeadlineExpiredEvent extends BasicEvent {
  type: EventType.BID_DEADLINE_EXPIRED;
  bidDeadline: Date;
}

export interface StageDeadlineExpiredEvent extends BasicEvent {
  type: EventType.STAGE_DEADLINE_EXPIRED;
  stageId: string;
  completionDeadline: Date;
}

export interface EmailsSentEvent extends BasicEvent {
  type: EventType.EMAILS_SENT;
  emailType: string;
  userIdsByCompanyId: {
    [key in string]: string[];
  };
  details?: any;
}

export interface RequestSentEvent extends BasicEvent {
  type: EventType.REQUEST_SENT;
  companyId: string;
  usersByRecipientId: {
    [key in string]: (UserMinimized & { isOwner?: boolean })[]
  };
}

export interface SettingsUpdatedEvent extends BasicEvent {
  type: EventType.SETTINGS_CHANGED;
  settings: Partial<PublishableSettings>;
}

export interface LiveSettingsUpdatedEvent extends BasicEvent {
  type: EventType.LIVE_SETTINGS_CHANGED;
  settings: Partial<LiveSettings>;
}

export interface SpendAndSavingsChangedEvent extends BasicEvent {
  type: EventType.SPEND_AND_SAVINGS_CHANGED;
  eventVersion: number;
  companyId: string;
  spendAndSavings: Partial<RfxSpendAndSavings>;
}

export interface RequestCurrencyChangedEvent extends BasicEvent {
  type: EventType.REQUEST_CURRENCY_CHANGED;
  companyId: string;
  currencyCode: string;
}

export interface RequestRevisedEvent extends BasicEvent {
  type: EventType.REQUEST_REVISED;
  companyId: string;
  message: string;
}

export interface RecipientsAddedEvent extends BasicEvent {
  type: EventType.RECIPIENTS_ADDED;
  companyId: string;
  recipients: RecipientCompany[];
  usersByRecipientId: {
    [key in string]: (UserMinimized & { isOwner?: boolean })[]
  };
  /**
   * Old version of this event did not have this property
   */
  sourceByRecipientId?: {
    [key in string]: RecipientSource;
  };
}

export interface RecipientActivatedEvent extends BasicEvent {
  type: EventType.RECIPIENT_ACTIVATED;
  recipient: ActivatedRecipient;
}

/**
 * @deprecated
 */
export interface InterestExpressedEvent extends BasicEvent {
  type: EventType.INTEREST_EXPRESSED;
  recipientId: string;
  companyId: string;
  interest: boolean;
}

export interface PendingRecipientEmailStatusUpdatedEvent extends BasicEvent {
  type: EventType.PENDING_RECIPIENT_EMAIL_STATUS_UPDATED;
  recipientId: string;
  status: BidStatus.DELIVERED | BidStatus.OPENED | BidStatus.BOUNCED | BidStatus.BLOCKED;
}

export interface InvitationToTenderSentEvent extends BasicEvent {
  type: EventType.INVITATION_TO_TENDER_SENT;
  recipientId: string;
  companyId: string;
}

export interface StageEnteredEvent extends BasicEvent {
  type: EventType.STAGE_ENTERED;
  recipientId: string;
  companyId: string;
  stageId: string;
}

export interface IntentionUpdatedEvent extends BasicEvent {
  type: EventType.INTENTION_UPDATED;
  recipientId: string;
  companyId: string;
  stageId: string;
  intention: boolean;
  isIntentionToBid?: boolean;
  reason?: string;
}

export interface LotIntentionUpdatedEvent extends BasicEvent {
  type: EventType.LOT_INTENTION_UPDATED;
  recipientId: string;
  companyId: string;
  lotId: string;
  intention: LotIntentionStatus;
}

export interface BidWithdrawnEvent extends BasicEvent {
  type: EventType.BID_WITHDRAWN;
  recipientId: string;
  companyId: string;
  stageId: string;
  reason: string;
}

export interface BidReopenedEvent extends BasicEvent {
  type: EventType.BID_REOPENED;
  recipientId: string;
  companyId: string;
  stageId: string;
}

export interface ReplySentEvent extends BasicEvent {
  type: EventType.REPLY_SENT;
  recipientId: string;
  companyId: string;
}

export interface ExchangesInitiatedEvent extends BasicEvent {
  type: EventType.EXCHANGES_INITIATED;
  recipientId: string;
  companyId: string;
  sectionName: string;
  docXDefs: ExchangeDefinition[];
}

export interface ExchangeReplySentEvent extends BasicEvent {
  type: EventType.EXCHANGE_REPLY_SENT;
  recipientId: string;
  companyId: string;
  docXDefId: string;
  action: Action;
}

export interface PricingSentEvent extends BasicEvent {
  type: EventType.PRICING_SENT;
  recipientId: string;
  companyId: string;
  pricing: Record<string, number>;
}

export interface PageApprovedEvent extends BasicEvent {
  type: EventType.PAGE_APPROVED;
  recipientId: string;
  companyId: string;
  pageId: string;
}

export interface ExchangesUnlockedEvent extends BasicEvent {
  type: EventType.EXCHANGES_UNLOCKED;
  companyId: string;
  recipientIds: string[];
  sectionIds?: string[]; // Did not exist on early versions of the event
  exchangeIds: string[];
}

export interface BidAwardedEvent extends BasicEvent {
  type: EventType.BID_AWARDED;
  companyId: string;
  recipientId: string;
  message: string;
  /** @deprecated */
  attachments?: Attachment[];
  /** @deprecated */
  winnerIds?: string[];
  /** @deprecated */
  winnerId?: string;
}

export interface BidRejectedEvent extends BasicEvent {
  type: EventType.BID_REJECTED;
  companyId: string;
  recipientId: string;
}

export interface BidReinstatedEvent extends BasicEvent {
  type: EventType.BID_REINSTATED;
  companyId: string;
  recipientId: string;
}

export interface RequestAwardedEvent extends BasicEvent {
  type: EventType.REQUEST_AWARDED;
  companyId: string;
  details?: AwardOrCloseRequestDetails;
}

export interface RequestClosedEvent extends BasicEvent {
  type: EventType.REQUEST_CLOSED;
  companyId: string;
  details?: AwardOrCloseRequestDetails;
}

export interface RequestDeletedEvent extends BasicEvent {
  type: EventType.REQUEST_DELETED;
  companyId?: string;
}

export interface ApprovalRequestedEvent extends BasicEvent {
  type: EventType.APPROVAL_REQUESTED;
  approvalRequestId: string;
  companyId: string;
  stageId: string;
  approverIds: string[];
  recipientIds: string[];
  message: string;
  attachments: Attachment[];
}

export interface ApprovalRequestCancelledEvent extends BasicEvent {
  type: EventType.APPROVAL_REQUEST_CANCELLED;
  approvalRequestId: string;
  stageId: string;
}

export interface ApprovalDecidedEvent extends BasicEvent {
  type: EventType.APPROVAL_DECIDED;
  approvalRequestId: string;
  stageId: string;
  isApproved: boolean;
  message: string;
}

export interface InternalDocumentAddedEvent extends BasicEvent {
  type: EventType.INTERNAL_DOCUMENT_ADDED;
  companyId: string;
  documentId: string;
  documentName: string;
  attachment: Attachment;
  comment: string;
  stageId: string;
}

export interface InternalExchangeReplySentEvent extends BasicEvent {
  type: EventType.INTERNAL_EXCHANGE_REPLY_SENT;
  companyId: string;
  exchangeId: string;
  action: Action;
}

export interface ExchangesChangedEvent extends BasicEvent {
  type: EventType.EXCHANGES_CHANGED;
  recipientId: string;
  companyId: string;
  changes: ExchangeChange[];
}

export interface AssetsChangedEvent extends BasicEvent {
  type: EventType.ASSETS_CHANGED;
  recipientId: string;
  companyId: string;
  sectionId: string;
  changes: AssetChange[];
}

export interface AuctionStartedEvent extends BasicEvent {
  type: EventType.AUCTION_STARTED;
  auctionId: string;
  startDate: string;
}

export interface AuctionEndedEvent extends BasicEvent {
  type: EventType.AUCTION_ENDED;
  auctionId: string;
  date: string;
}

export interface AuctionLotBiddingClosedEvent extends BasicEvent {
  type: EventType.AUCTION_LOT_BIDDING_CLOSED;
  auctionId: string;
  lotId: string;
  endDate: Date;
  date: Date;
}

export interface AuctionLotBidSubmittedEvent extends BasicEvent {
  type: EventType.AUCTION_LOT_BID_SUBMITTED;
  auctionId: string;
  lotId: string;
  bid: {
    bidderId: string;
    price: number;
    breakdown: {
      [key: string]: { price: number; quantity: number };
    };
  };
}

export interface AuctionLotBidExtendedEvent extends BasicEvent {
  type: EventType.AUCTION_LOT_BIDDING_EXTENDED;
  date: string;
  auctionId: string;
  lotId: string;
  extensionSec: number;
  trigger: ExtensionTrigger;
}

export interface AuctionBiddingExtendedEvent extends BasicEvent {
  type: EventType.AUCTION_BIDDING_EXTENDED;
  auctionId: string;
  extensionSec: number;
  trigger: ExtensionTrigger;
}

export interface AuctionLotBidRetractionRequestedEvent extends BasicEvent {
  type: EventType.AUCTION_LOT_BID_RETRACTION_REQUESTED;
  auctionId: string;
  lotId: string;
  bidderId: string;
  price: number;
}

export interface AuctionCancelledEvent extends BasicEvent {
  type: EventType.AUCTION_CANCELLED;
  auctionId: string;
}

export interface AuctionBiddingPausedEvent extends BasicEvent {
  type: EventType.AUCTION_BIDDING_PAUSED;
  auctionId: string;
}

export interface AuctionBiddingResumedEvent extends BasicEvent {
  type: EventType.AUCTION_BIDDING_RESUMED;
  auctionId: string;
}

export interface ReferenceNumberOffsetUpdated extends BasicEvent {
  type: EventType.REFERENCE_NUMBER_OFFSET_UPDATED;
  adjustmentValue: number;
  referenceNumberOffset: number;
}

export type RawRfqEnvelope = {
  _id: string;
  events: RfqEvent[];
};

export enum BulletinType {
  CLARIFICATION = 'clarification',
  /** @deprecated */
  QUESTION = 'question',
  /** @deprecated */
  ANSWER = 'answer',
}

export interface Bulletin {
  _id: string;
  readonly createdAt: Date;
  updatedAt: Date;
  message: string;
  userId: string;
  userName: string;
  rfqId: string;
  _type: BulletinType;
  /** @deprecated */
  questionId?: string;
  /** @deprecated */
  section?: string;
  /** @deprecated */
  category?: string;
  /** @deprecated */
  companyId?: string;
  /** @deprecated */
  companyName?: string;
}

export enum QuestionAddressField {
  COMPANY_NAME = 'companyName',
  LINE_ONE = 'lineOne',
  LINE_TWO = 'lineTwo',
  CITY = 'city',
  STATE = 'state',
  POSTCODE = 'postcode',
  COUNTRY = 'country',
  CONTACT_NAME = 'contactName',
  EMAIL = 'email',
  PHONE = 'phone',
}

export type PriceWithCurrency = {
  currencyCode: string;
  amount: number | null;
};

export type QuestionAddress = {
  [key in QuestionAddressField]?: string;
};

export type QuestionDocument = {
  selectedOption: YesNoOption | UploadCannotProvideOption;
  attachments: Attachment[];
  expiryDate: Date;
};

export type QuestionGrid = {
  currencyCode?: string;
  rows: {
    _id: string;
    values: {
      [key: string]: any;
    };
  }[];
};

export type QuestionYesNo = {
  selectedOption: YesNoOption;
  moreInformation: string;
  attachments: Attachment[];
};

type QuestionResponseValue =
  | string
  | string[]
  | number
  | null
  | QuestionAddress
  | PriceWithCurrency
  | SerializedDate
  | QuestionDocument
  | QuestionGrid
  | QuestionYesNo;

export interface QuestionResponse {
  value: QuestionResponseValue;
  noAnswer?: boolean;
}

export type QuestionResponseByType<T extends QuestionType> = {
  noAnswer?: boolean;
  value:
    T extends QuestionType.PRICE ? PriceWithCurrency :
    T extends QuestionType.ADDRESS ? QuestionAddress :
    T extends QuestionType.CHECKBOXES ? string[] :
    T extends QuestionType.SHORT_TEXT ? string | number | null :
    T extends QuestionType.DATE_TIME ? SerializedDate :
    T extends QuestionType.DOCUMENT ? QuestionDocument :
    T extends QuestionType.GRID ? QuestionGrid :
    T extends QuestionType.YES_NO ? QuestionYesNo :
    string;
};

export enum AuctionStatus {
  PENDING = 'pending',
  ACTIVE = 'active',
  PAUSED = 'paused',
  ENDED = 'ended',
  CANCELLED = 'cancelled',
}

/**
 * Bidding duration can be extended through 3 different ways:
 *   - `newLeadBid` - a new _lead_ bid has been submitted
 *   - `anyBid` - any new bid has been submitted
 *   - `otherLot` - a different lot gets an extension, this can have knock-on
 *                  extensions in other lots (dependent on the auction sequence type)
 *   - `pause` - if the auction has been paused and then resumed
 *   - `admin` - if the auction deadline was extended from the admin client
 */
export enum ExtensionTrigger {
  NEW_LEAD_BID = 'newLeadBid',
  ANY_BID = 'anyBid',
  OTHER_LOT = 'otherLot',
  PAUSE = 'pause',
  ADMIN = 'admin',
}

export enum RevisionChangeType {
  AUCTION_START_DATE = 'auction-start-date',
  AUCTION_LOT_DURATION = 'auction-lot-duration',
  AUCTION_INFORMATION = 'auction-information',
  AUCTION_TERMS = 'auction-terms',
  AUCTION_LOT_CURRENCY = 'auction-lot-currency',
  AUCTION_LOT_DECIMAL_PLACES = 'auction-lot-decimal-places',
  AUCTION_LOT_BID_FEEDBACK = 'auction-lot-bid-feedback',
  AUCTION_LOT_MINIMUM_REDUCTION = 'auction-lot-minimum-reduction',
  AUCTION_LOT_TIE_BIDS = 'auction-lot-tie-bids',
  AUCTION_LOT_CEILING_PRICE = 'auction-lot-ceiling-price',
  AUCTION_LOT_AUTO_EXTENSION = 'auction-lot-auto-extension',
  AUCTION_LOT_PRE_BIDS = 'auction-lot-pre-bids',
  AUCTION_LOT_LINE_ITEMS = 'auction-lot-line-items',
}

export enum RevisionChangeGroup {
  AUCTION_SCHEDULE = 'auction-schedule',
  AUCTION_AWARDING_PRINCIPLES = 'auction-awarding-principles',
  AUCTION_BIDDER_AGREEMENT = 'auction-bidder-agreement',
  AUCTION_CURRENCY = 'auction-currency',
  AUCTION_DECIMAL_PLACES = 'auction-decimal-places',
  AUCTION_BID_FEEDBACK = 'auction-bid-feedback',
  AUCTION_BID_RULES = 'auction-bid-rules',
  AUCTION_TIMING_RULES = 'auction-timing-rules',
  AUCTION_LINE_ITEMS = 'auction-line-items',
}

export type RevisionChange = {
  type: RevisionChangeType;
  group: RevisionChangeGroup;
  previousValue: any;
  updatedValue: any;
};

export type ExternalSupplier = {
  externalSupplierId: string;
  externalSupplierName: string;
  externalSupplierContactEmails: string;
};

export interface ExchangeRateDocument {
  /**
   * Date in the YYYY-MM-DD/yyyy-MM-dd format (eg 2022-05-30)
   */
  date: string;
  base: string;
  timestamp: number;
  rates: {
    [currencyCode: string]: number;
  }
}

export type Language = {
  code: string;
  isDefault: boolean;
  isEnabled: boolean;
};

export enum TotalSavingsCalculationMethod {
  SUM_SPECIFIC_SAVINGS = 'sumSpecificSavings',
  BUDGET_FINAL_VALUE_DIFF = 'budgetFinalValueDiff',
  MANUAL = 'manual',
}

export type RfxSummary = {
  subject: string;
  reference: string;
  description: string;
  pqqRequestId: string;
  attachments: Attachment[];
  isEnhancedListing: boolean;
  /**
   * @deprecated
   */
  approximateTotal?: ApproximateTotal;
  /**
   * The product and service tags added to the request by a sender.
   */
  productsAndServices: ProductTag[];
};

export enum SavingsType {
  SINGLE_RESPONSE_LINE_ITEMS = 'singleResponseLineItems',
  MULTI_RESPONSE_LINE_ITEMS = 'multiResponseLineItems',
  AUCTION_LINE_ITEMS = 'auctionLineItems',
}

export type CostAndSavings = Record<SavingsType, {
  exchangeId: string;
  linkedExchangeId?: string;
  valueA: number | null;
  valueB: number | null;
  valueBStageIndex?: number;
}[]>;

export type CostAndSavingsByRecipientId = Record<string, CostAndSavings>;

export type Savings = {
  type: SavingsType;
  valueAByRecipientId: Record<string, number>;
  valueBByRecipientId: Record<string, number>;
};

export type SavingsBySavingsType = {
  [Key in SavingsType]?: Savings
};

export enum RequestNotAccurateReason {
  BAD_PRICES_STRUCTURE = 'badPricesStructure',
  PRICES_NOT_ACCOUNTED_FOR = 'pricesNotAccountedFor',
  MULTIPLE_LINE_ITEMS_USED = 'multipleLineItemsUsed',
  BUSINESS_SHARED = 'businessShared',
  PRICING_MULTIPLE_SCENARIOS = 'pricingMultipleScenarios',
  OTHER = 'other',
}

export enum NoLineItemsReason {
  NO_NEED = 'noNeed',
  SPREADSHEET = 'spreadsheet',
  SUPPLIERS_WONT_PROVIDE = 'suppliersWontProvide',
  TOO_TIME_CONSUMING = 'tooTimeConsuming',
  EFFECTIVE_USAGE = 'effectiveUsage',
  NO_FIT = 'noFit',
  OTHER = 'other',
}

export type SavingsCalculationResultByRecipientId = Record<string, { valueA: number; valueB: number; result: number }>;

export type SavingsCalculationResultByRecipientIdBySavingsType = Record<SavingsType, SavingsCalculationResultByRecipientId>;

/**
 * Buyer-only information about a request's spend and savings.
 */
export type RfxSpendAndSavings = {
  /**
   * Indicates whether or not spend and savings are enabled for the request.
   */
  enabled: boolean;
  /**
   * Indicates whether or not the request should have a budgeted total value.
   *
   * In live context, `hasBudgetedTotalValue` is redundant (it's always true
   * when `budgetedTotalValue` is a number, and false when `budgetedTotalValue`
   * is not a number).
   * In draft context, users can set `hasBudgetedTotalValue` while not
   * providing a `budgetedTotalValue`.
   */
  hasBudgetedTotalValue: boolean;
  /**
   * The budgeted total value of the request, or `null` if the request doesn't
   * have a budgeted total value.
   */
  budgetedTotalValue: number | null;
  /**
   * The calculated (machine-generated) final total value.
   */
  calculatedTotalValue: number | null;
  /**
   * `true` when a user has confirmed that the calculated value is accurate,
   * `false` when a user has indicated that the calculated values is
   * inaccurate, and `null` when the user hasn't provided information.
   */
  isCalculatedTotalValueAccurate: boolean | null;
  /**
   * `true` when a user has indicated that they can provide a final total
   * value manually, `false` when they've indicated that they can't, and
   * `null` when the user hasn't provided information.
   */
  canProvideManualTotalValue: boolean | null;
  /**
   * The final total value provided by the user.
   */
  manualTotalValue: number | null;
  /**
   * User-selected reasons that describe why the calculated total final value
   * is not accurate.
   */
  calculatedTotalValueNotAccurateReasons: RequestNotAccurateReason[] | null;
  /**
   * User-provided reasons in free-text form that describe why the calculated
   * total final value isn't accurate.
   */
  calculatedTotalValueNotAccurateOtherReason: string | null;
  /**
   * User-selected reasons that describe why the request did not include line
   * items.
   */
  noLineItemsReasons: NoLineItemsReason[] | null;
  /**
   * User-provided reasons in free-text form that describe why the request
   * did not include line items.
   */
  noLineItemsOtherReason: string | null;
  /**
   * The user-provided reasons why they can't provide a manual total final value.
   */
  cannotProvideManualTotalValueReason: string | null;
  /**
   * 'Additional comments' added when the calculated total value was not
   * accurate.
   *
   * Named 'awardQuestionnaireComment' for legacy reasons.
   */
  awardQuestionnaireComment: string | null;
  /**
   * `true` when a user has indicated that total savings can be expressed for
   * the request, `false` when they've indicated that this isn't possible, and
   * `null` when the user hasn't provided information.
   */
  canProvideTotalSavings: boolean | null;
  /**
   * The user-provided reason why they total savings can't be expressed for a request.
   */
  cannotProvideTotalSavingsReason: string | null;
  /**
   * The method that should be used to calculate the total savings.
   */
  totalSavingsCalculationMethod: TotalSavingsCalculationMethod | null;
  /**
   * Total savings provided by the user when `totalSavingsCalculationMethod`
   * is manual.
   */
  manualTotalSavings: number | null;
  /**
   * Description of the total savings provided by the user when
   * `totalSavingsCalculationMethod` is manual.
   */
  manualTotalSavingsDescription: string | null;
  /**
   * `true` when a user has confirmed that the total savings calculated
   * with the method provided in `totalSavingsCalculationMethod` are
   * accurate, `false` when a user has indicated that they aren't, and
   * `null` when the user hasn't provided information.
   */
  areTotalSavingsAccurate: boolean | null;
  /**
   * Calculated (machine-generated) data about savings in a request by
   * resource type.
   *
   * @deprecated
   */
  calculatedSpecificSavings: SavingsBySavingsType | null;
  /**
   * Calculated (machine-generated) data about savings in a request by
   * resource type.
   * Replaces the deprecated `calculatedSpecificSavings` property.
   */
  calculatedSavingsByType: SavingsCalculationResultByRecipientIdBySavingsType | null;
};

export type DisabledReason =
  | 'stage-deadline-expired'
  | 'bid-deadline-expired'
  | 'bid-withdrawn'
  | 'bid-rejected';

export enum ExtensionExpiry {
  NEXT_DEADLINE = 'next-deadline',
  EXCHANGE_COMPLETION = 'exchange-completion',
}

export type RfxBidSection = {
  readonly type: SectionType;
  status: 'empty' | 'inProgress' | 'blocked' | 'complete';
  stageIds: StageId[];
  numResolved: number;
  exchangeStateById: Record<ExchangeId, {
    type?: string;
    isInitiated: boolean;
    isResolved: boolean;
    isRecipientsTurn: boolean;
    stageIds?: StageId[];
  }>;
  excludeFromBidStatus: boolean;
};

export enum RecipientSource {
  SEARCH = 'search',
  PRE_QUAL = 'preQual',
  SUPPLIER_LIST = 'supplierList',
  INVITE = 'invite',
  PUBLIC_BID = 'publicBid',
  ADMIN = 'admin',
}

export type BidSubmissionExcelSection = {
  _id: string;
  name: string;
  lineItemsCount: number;
};

export enum LotsRevisionChangeType {
  LOTS_DISABLED = 'lots-disabled',
  LOTS_ENABLED = 'lots-enabled',
  LOT_ADDED = 'lot-added',
  LOT_MADE_OBSOLETE = 'lot-made-obsolete',
  LOT_RENAMED = 'lot-renamed',
  LOT_DESCRIPTION_CHANGED = 'lot-description-changed',
  LOTS_REORDERED = 'lots-reordered',
}

export type LotsRevisionChange =
  | {
    type: LotsRevisionChangeType.LOTS_DISABLED;
  }
  | {
    type: LotsRevisionChangeType.LOTS_ENABLED;
  }
  | {
    type: LotsRevisionChangeType.LOT_ADDED;
    name: string;
  }
  | {
    type: LotsRevisionChangeType.LOT_MADE_OBSOLETE;
    name: string;
  }
  | {
    type: LotsRevisionChangeType.LOT_RENAMED;
    previousName: string;
    currentName: string;
  }
  | {
    type: LotsRevisionChangeType.LOT_DESCRIPTION_CHANGED;
    name: string;
  }
  | {
    type: LotsRevisionChangeType.LOTS_REORDERED;
  };

export type LotsRevisionHistoryEvent = {
  type: 'lot-revised',
  timestamp: Date | number | string;
  changes: LotsRevisionChange[],
};

export type SupplierGroup = 'awarded' | 'unsuccessful' | 'previouslyUnsuccessful' | 'nonBidding';

export enum AwardScenario {
  REQUEST_LEVEL_AWARD = 'requestLevelAward',
  LINE_LEVEL_AWARD = 'lineLevelAward',
  LOT_LEVEL_AWARD = 'lotLevelAward',
  NO_AWARD = 'noAward',
}

export type Award = {
  recipientId: string;
  /**
   * Only used in the context of line-level awards.
   *
   * The `awardedExchangeId` is the ID of the exchange
   * on which the calculation of the awarded cost is based.
   * In the context of an auction line item that's linked to a
   * line item from an exchange stage, this is the ID of
   * the exchange that contains the latest supplier response.
   *
   * NB In the context of request awards, we generally render
   * and keep track of linked auction line items by the ID
   * of the linked exchange stage line item, so the
   * `awardedExchangeId` does not necessarily match the keys
   * of the `awardDecisionByExchangeId` and `splitDecisionByExchangeId`
   * objects.
   */
  awardedExchangeId?: string;
  requestCurrencyAwardedCost?: number;
};

/**
 * Stored in the AwardFlowData
 */
export type AwardDecision =
  | {
    value: 'award';
    awards: Award[];
  }
  | {
    value: 'noAward';
  }
  | {
    value: 'markAsObsolete';
  };

export type SplitDecision =
  | {
    splitBy: 'quantity';
    quantitySplitMode: 'amount' | 'percentage';
    decisionByRecipientId: Record<string, {
      /**
       * Added statically in split award step.
       */
      originalPricePerUnit?: number;
      /**
       * Added statically in split award step.
       */
      originalQuantity?: number;
      /**
       * Provided by user in split award step when `quantitySplitMode` is `amount`;
       * otherwise calculated in split award step.
       */
      awardedQuantityAmount?: number;
      /**
       * Provided by user in split award step when `quantitySplitMode` is `percentage`;
       * otherwise calculated in split award step.
       */
      awardedQuantityPercentage?: number;
      /**
       * Added statically in split award step.
       */
      supplierCurrencyCode?: string;
      /**
       * Calculated in split award step.
       */
      supplierCurrencyAwardedCost?: number;
      /**
       * Calculated in split award step.
       */
      requestCurrencyAwardedCost?: number;
    }>;
  };

  export type Message = {
    text: string;
    attachments: Attachment[];
  };

export type MessageAndSupplierIds = { message: Message; supplierIds: string[] };

export type MessagesBySupplierGroup = Record<SupplierGroup, MessageAndSupplierIds[]>;

export type AwardOrCloseRequestDetails = {
  supplierIdsByGroup: {
    awarded: string[];
    unsuccessful: string[];
    previouslyUnsuccessful: string[];
    nonBidding: string[];
  };
  awardScenario: AwardScenario;
  requestAward?: Award;
  awardDecisionByLotId?: Record<string, AwardDecision | null>;
  awardDecisionByExchangeId?: Record<string, AwardDecision | null>;
  splitDecisionByExchangeId?: Record<string, SplitDecision | null>;
};

export type AwardOrCloseRequestPayload = AwardOrCloseRequestDetails & {
  spendAndSavings?: RfxSpendAndSavings;
  messagesBySupplierGroup: MessagesBySupplierGroup;
};
